import { GAIA_CAMERA_POSITION, GAIA_FOV } from "@/utils/cameraUtil";
import { css } from "@emotion/react";
import {
  CameraControls,
  Environment,
  PerformanceMonitor,
  // Stats,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { useRef, useState } from "react";
import * as THREE from "three";
// import { Perf } from "r3f-perf";
import { PreloadAssets } from "@/engine/viewer/PreloadAssets";
import { ViewerContextConsumer } from "@/engine/viewer/ViewerContextConsumer";
import { ViewerContextProvider } from "@/engine/viewer/ViewerContextProvider";
import {
  useViewerGaiaStore,
  ViewerState,
} from "@/engine/viewer/core/useViewerStore";
import {
  ENVIRONMENT_INTENSITY_CONSTANT,
  GAIA_AMBIENT_LIGHT_COLOR,
  GAIA_AMBIENT_LIGHT_INTENSITY,
  GAIA_AMBIENT_LIGHT_INTENSITY_CONSTANT,
  GAIA_DIRECTIONAL_LIGHT_INTENSITY,
  GAIA_DIRECTIONAL_LIGHT_INTENSITY_CONSTANT,
} from "@/utils/gaiaUtil";
import { HengeCameraControls } from "@/utils/threeUtil";
import withCSR from "@/components/HOC/withCSR";
import { StoreApi, UseBoundStore } from "zustand";
// import WebGPURenderer, {
//   WebGPURendererParameters,
// } from "three/src/renderers/webgpu/WebGPURenderer";
// import { LayerMaterial } from "lamina";

// import dynamic from "next/dynamic";
// const EffectComposer = dynamic(
//   () => import("@react-three/postprocessing").then((mod) => mod.EffectComposer),
//   {
//     ssr: false,
//   },
// );
// const ToneMapping = dynamic(
//   () => import("@react-three/postprocessing").then((mod) => mod.ToneMapping),
//   {
//     ssr: false,
//   },
// );
// const Bloom = dynamic(
//   () => import("@react-three/postprocessing").then((mod) => mod.Bloom),
//   {
//     ssr: false,
//   },
// );
// const SMAA = dynamic(
//   () => import("@react-three/postprocessing").then((mod) => mod.SMAA),
//   {
//     ssr: false,
//   },
// );

interface Props {
  gaiaLinkedViewerStore: UseBoundStore<StoreApi<ViewerState>>;
  engine?: "gl" | "gpu";
  lights?: React.ReactNode;
  children?: React.ReactElement[] | React.ReactElement;
}

const isDev = process.env.NODE_ENV === "development";
const isLocal =
  process.env.NODE_ENV === "production" && process.env.BUILD_MODE === "local";

// let CustomWebGPURenderer: {
//   new (arg0: {
//     canvas: HTMLCanvasElement | OffscreenCanvas;
//     alpha: boolean;
//     antialias: boolean;
//     preserveDrawingBuffer: boolean;
//     // logarithmicDepthBuffer: boolean;
//     toneMapping: 7;
//     toneMappingExposure: number;
//   }): any;
//   new (parameters?: WebGPURendererParameters): WebGPURenderer;
//   prototype?: any;
// };

const Viewer = ({ gaiaLinkedViewerStore, lights, children }: Props) => {
  const [degraded, degrade] = useState(false);
  const cameraControlsRef = useRef<HengeCameraControls | null>(null);

  const backgroundIntensity = useViewerGaiaStore(
    gaiaLinkedViewerStore,
    (state) => state.backgroundIntensity,
  );
  const toneMappingExposure = useViewerGaiaStore(
    gaiaLinkedViewerStore,
    (state) => state.toneMappingExposure,
  );

  // const [isWebGPUAvailable, setIsWebGPUAvailable] = useState(false);
  // const [isReady, setIsReady] = useState(false);
  //
  // useEffect(() => {
  //   const fn = async () => {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-expect-error
  //     const capabilities = (await import("three/addons/capabilities/WebGPU.js"))
  //       .default;
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-expect-error
  //     CustomWebGPURenderer = (
  //       await import("three/src/renderers/webgpu/WebGPURenderer.js")
  //     ).default;
  //
  //     setIsWebGPUAvailable(capabilities.isAvailable());
  //     setIsReady(true);
  //   };
  //   fn();
  // }, []);

  return (
    <div
      css={css`
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #fdfdfd;
        overflow: hidden;

        & canvas {
          max-width: 100%;
          max-height: 100%;
        }
      `}
    >
      {/*{isReady && (*/}
      {
        <Canvas
          //{...(engine === "gpu" &&
          //  isWebGPUAvailable && {
          //    gl: (canvas) => {
          //      if (!CustomWebGPURenderer) {
          //        throw Error("WebGPU renderer not loaded");
          //      }
          //      const r = new CustomWebGPURenderer({
          //        canvas,
          //        alpha: true,
          //        antialias: true,
          //        preserveDrawingBuffer: true,
          //        logarithmicDepthBuffer: true,
          //        toneMapping: THREE.NeutralToneMapping,
          //        toneMappingExposure,
          //      });
          //      r.xr = {
          //        addEventListener: () => {},
          //      };
          //
          //      console.log("Engine is powered by WebGPU.");
          //
          //      return r;
          //    },
          //  })}
          gl={{
            alpha: true,
            antialias: true,
            preserveDrawingBuffer: true,
            logarithmicDepthBuffer: true,
            precision: "lowp",
            powerPreference: "low-power",
            toneMapping: THREE.ACESFilmicToneMapping,
            toneMappingExposure,
          }}
          // performance={{
          //   current: 1,
          //   min: 0.1,
          //   max: 1,
          //   debounce: 200,
          // }}
          shadows
          camera={{
            fov: GAIA_FOV,
            near: 0.01,
            far: 12000,
            position: GAIA_CAMERA_POSITION,
            zoom: 10,
          }}
          orthographic
        >
          {(isDev || isLocal) && (
            <>
              {/*<Stats />*/}
              {/*<Perf*/}
              {/*  position={"bottom-left"}*/}
              {/*  style={{*/}
              {/*    pointerEvents: "none",*/}
              {/*    opacity: 0.8,*/}
              {/*  }}*/}
              {/*/>*/}
            </>
          )}
          <React.Suspense fallback={null}>
            <PerformanceMonitor onDecline={() => degrade(true)} />
            <Environment
              frames={degraded ? 1 : Infinity}
              resolution={1024}
              environmentIntensity={
                backgroundIntensity * ENVIRONMENT_INTENSITY_CONSTANT
              }
              backgroundIntensity={0}
              files={`${process.env.ASSET_URL ?? ""}/assets/environments/hdri/studio_small_09_1k.hdr`}
            />
            {/*<Environment*/}
            {/*  background={"only"}*/}
            {/*  backgroundIntensity={*/}
            {/*    backgroundIntensity * BACKGROUND_INTENSITY_CONSTANT*/}
            {/*  }*/}
            {/*>*/}
            {/*  <mesh scale={100}>*/}
            {/*    <sphereGeometry args={[1, 64, 64]} />*/}
            {/*    <LayerMaterial color={"#ffffff"} side={THREE.BackSide} />*/}
            {/*  </mesh>*/}
            {/*</Environment>*/}

            {/*<color attach={"background"} args={["#ffffff"]} />*/}
            {/*<SoftShadows />*/}
            {lights ? (
              lights
            ) : (
              <group name={"SceneLightGroup"}>
                <ambientLight
                  args={[
                    GAIA_AMBIENT_LIGHT_COLOR,
                    backgroundIntensity *
                      GAIA_AMBIENT_LIGHT_INTENSITY_CONSTANT *
                      GAIA_AMBIENT_LIGHT_INTENSITY,
                  ]}
                  name={"DefaultAmbientLight"}
                />
                <directionalLight
                  args={[
                    GAIA_AMBIENT_LIGHT_COLOR,
                    backgroundIntensity *
                      GAIA_DIRECTIONAL_LIGHT_INTENSITY_CONSTANT *
                      GAIA_DIRECTIONAL_LIGHT_INTENSITY,
                  ]}
                  position={[4, 3, 2]}
                  castShadow
                  // shadow-bias={-0.0001}
                  // shadow-normalBias={0.02}
                  shadow-camera-left={-8}
                  shadow-camera-top={8}
                  shadow-camera-right={8}
                  shadow-camera-bottom={-8}
                  shadow-camera-near={0.1}
                  shadow-camera-far={20}
                  shadow-mapSize-width={4096}
                  shadow-mapSize-height={4096}
                  name={"DefaultShadowLight"}
                />
                {/*<directionalLight*/}
                {/*  args={[0xffffff, 0.1]}*/}
                {/*  position={[8, 0, 4]}*/}
                {/*  name={"LightModeSupportLight"}*/}
                {/*/>*/}
                {/*<spotLight*/}
                {/*  args={[0xffffff, 1.2, 24, Math.PI / 3, 0.01, 0.01]}*/}
                {/*  position={[8, 0, 4]}*/}
                {/*  castShadow*/}
                {/*  shadow-bias={-0.002}*/}
                {/*  shadow-normalBias={0.01}*/}
                {/*  shadow-mapSize-width={4096}*/}
                {/*  shadow-mapSize-height={4096}*/}
                {/*  name={"DarkModeSpotLight"}*/}
                {/*  visible={false}*/}
                {/*/>*/}
              </group>
            )}
            {/*<EffectComposer multisampling={0}>*/}
            {/*  <SMAA />*/}
            {/*  <ToneMapping*/}
            {/*    mode={THREE.ReinhardToneMapping}*/}
            {/*    blendFunction={BlendFunction.AVERAGE}*/}
            {/*    whitePoint={0.8}*/}
            {/*  />*/}
            {/*  <Noise opacity={0.025} />*/}
            {/*</EffectComposer>*/}
            <ViewerContextProvider
              gaiaLinkedViewerStore={gaiaLinkedViewerStore}
            />
            <ViewerContextConsumer
              gaiaLinkedViewerStore={gaiaLinkedViewerStore}
            >
              {children}
            </ViewerContextConsumer>
            <CameraControls ref={cameraControlsRef} makeDefault />
          </React.Suspense>

          <React.Suspense>
            <PreloadAssets />
          </React.Suspense>

          {/*<EffectComposer>*/}
          {/*  <ToneMapping mode={6} />*/}
          {/*  <SMAA />*/}
          {/*  <Bloom*/}
          {/*    mipmapBlur*/}
          {/*    intensity={viewingHengeId ? 0.1 : 0}*/}
          {/*    radius={0.75}*/}
          {/*    luminanceThreshold={0.5}*/}
          {/*  />*/}
          {/*</EffectComposer>*/}
        </Canvas>
      }
    </div>
  );
};

export default withCSR(Viewer);
