import { LoginStrategyProps } from "@/components/login/strategy/map/LoginStrategyMap";
import {
  GOOGLE_OAUTH_POPUP_HEIGHT,
  GOOGLE_OAUTH_POPUP_WIDTH,
  GOOGLE_OAUTH_RESPONSE_TYPE,
  GOOGLE_OAUTH_SCOPE_LIST,
  GOOGLE_OAUTH_URI,
} from "@/components/signup/strategy/oauth/google/google-oauth-constants";
import { GoogleOAuthWebButton } from "@/components/signup/strategy/oauth/google/GoogleOAuthWebButton";
import { GA4_CUSTOM_TRIGGER } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import {
  useMember,
  useMemberNextAuthSession,
  useMemberStoreActions,
} from "@/stores/zustand/member/useMemberStore";
import {
  OAuthStore,
  useOAuthGenerateTokensResponse,
  useOAuthSecurityCode,
  useOAuthStoreActions,
} from "@/stores/zustand/oauth/OAuthStore";
import { useGlobalActions } from "@/stores/zustand/useGlobalStore";
import { ApiResultEnum, ApiResultInterface } from "@/types/api-result";
import { ContinueSignupOAuthResponse } from "@/types/api/auth/response/auth-response";
import { HResponse } from "@/types/api/response";
import { AUTH_METHOD, AUTH_PROVIDER } from "@/types/common-types";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";

type ContinueLoginGoogleOAuthProps = LoginStrategyProps;

export const LoginWithGoogleOAuth = ({
  authMethod,
  setAuthMethod,
  authProvider,
  setAuthProvider,
  onLogIn,
}: ContinueLoginGoogleOAuthProps) => {
  const member = useMember();
  const nextAuthSession = useMemberNextAuthSession();
  const { terminate } = useMemberStoreActions();

  const router = useRouter();
  const { showPopupError } = useGlobalActions();

  const oauthSecurityCode = useOAuthSecurityCode();
  const oauthGenerateTokensResponse = useOAuthGenerateTokensResponse();
  const {
    generateSecurityToken,
    setSecurityToken,
    updateGenerateTokensResponse,
  } = useOAuthStoreActions();

  useEffect(() => {
    if (!oauthSecurityCode) return;
    if (!oauthGenerateTokensResponse) return;

    signIn("google-oauth", {
      generateTokensResponse: JSON.stringify(oauthGenerateTokensResponse),
      redirect: false,
    }).then((res) => {
      if (res?.ok) {
        window.dataLayer.push(
          GA4_CUSTOM_TRIGGER.Fetch.Log_In(authMethod, authProvider),
        );

        if (onLogIn) {
          onLogIn();
        } else {
          router.push("/home");
        }
      } else if (res?.error) {
        terminate();
        try {
          const error: HResponse = JSON.parse(res.error);

          const apiResult: ApiResultInterface =
            ApiResultEnum.fromCode(error.code) ?? ApiResultEnum.ERROR_SYSTEM;

          if (apiResult.code === ApiResultEnum.CONTINUE_SIGNUP_OAUTH.code) {
            const resData = (error as ContinueSignupOAuthResponse).data;
            router.push(
              {
                pathname: "/signup/continue",
                query: {
                  redirectUri: "/login",
                  authMethod: resData.authMethod,
                  authProvider: resData.authProvider,
                  oauthSecurityCode,
                  email: resData.email,
                  // displayName: resData.displayName,
                  familyName: resData.familyName,
                  givenName: resData.givenName,
                },
              },
              "/signup/continue",
            );
          } else {
            showPopupError(apiResult.localedMessages.ENG.popupMessageList);
          }
        } catch (e) {
          terminate();
          showPopupError();
          void e;
        }
      } else {
        terminate();
        showPopupError();
      }
    });

    setSecurityToken(null);
    updateGenerateTokensResponse(null, null);
    window.__HENGE__.OAuthStore = undefined;
  }, [oauthSecurityCode, oauthGenerateTokensResponse]);

  async function onClickHandler() {
    const popupTop =
      window.screenTop +
      document.body.clientHeight / 2 -
      GOOGLE_OAUTH_POPUP_HEIGHT / 2;
    const popupLeft =
      window.screenLeft +
      document.body.clientWidth / 2 -
      GOOGLE_OAUTH_POPUP_WIDTH / 2;

    const securityToken = await generateSecurityToken(
      window.navigator.userAgent,
    );
    setSecurityToken(securityToken);

    window.__HENGE__.OAuthStore = OAuthStore;
    window.open(
      `${GOOGLE_OAUTH_URI}?client_id=${
        process.env.GOOGLE_OAUTH_CLIENT_ID
      }&response_type=${GOOGLE_OAUTH_RESPONSE_TYPE}&redirect_uri=${
        process.env.GOOGLE_OAUTH_REDIRECT_URI
      }&scope=${GOOGLE_OAUTH_SCOPE_LIST.join(" ")}&state=security_token%3D${securityToken}`,
      "_blank",
      `popup=1,top=${popupTop},left=${popupLeft},width=${GOOGLE_OAUTH_POPUP_WIDTH},height=${GOOGLE_OAUTH_POPUP_HEIGHT}`,
    );
  }

  return (
    <>
      <GoogleOAuthWebButton
        onClick={() => {
          if (
            member &&
            nextAuthSession.data?.user &&
            nextAuthSession.status === "authenticated"
          ) {
            if (onLogIn) {
              onLogIn();
            } else {
              router.push("/home");
            }
            return;
          }

          setAuthMethod(AUTH_METHOD.OAUTH);
          setAuthProvider(AUTH_PROVIDER.GOOGLE);

          onClickHandler();
        }}
      />
    </>
  );
};
