import { css } from "@emotion/react";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
// import { useTranslation } from "next-i18next";
import { LoginStrategyProps } from "@/components/login/strategy/map/LoginStrategyMap";
import { GA4_CUSTOM_TRIGGER } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import {
  useMember,
  useMemberNextAuthSession,
  useMemberStoreActions,
} from "@/stores/zustand/member/useMemberStore";
import { useGlobalActions } from "@/stores/zustand/useGlobalStore";
import { ApiResultEnum, ApiResultInterface } from "@/types/api-result";
import { NotVerifiedVerificationResponse } from "@/types/api/auth/response/auth-response";
import { HResponse } from "@/types/api/response";
import { AUTH_METHOD, AUTH_PROVIDER } from "@/types/common-types";
import { signIn } from "next-auth/react";

type ContinueLoginWithNativeProps = LoginStrategyProps;

export const LoginWithNative = ({
  authMethod,
  setAuthMethod,
  authProvider,
  setAuthProvider,
  onLogIn,
}: ContinueLoginWithNativeProps) => {
  // const { t } = useTranslation("common");
  // const continueButtonMessage = t("continue-with-henge-id");

  const member = useMember();
  const nextAuthSession = useMemberNextAuthSession();
  const { terminate } = useMemberStoreActions();

  const router = useRouter();
  const { showPopupError } = useGlobalActions();

  const HengeIDInputRef = useRef<HTMLInputElement>(null!);
  const [HengeID, setHengeID] = useState<string>("");
  const HengeIDRef = useRef<string>("");
  useEffect(() => {
    HengeIDRef.current = HengeID;
  }, [HengeID]);
  useEffect(() => {
    if (router.isReady && router.query.HengeID) {
      setHengeID(String(router.query.HengeID));
      return;
    }
  }, [router]);

  const passwordInputRef = useRef<HTMLInputElement>(null!);
  const [password, setPassword] = useState<string>("");
  const passwordRef = useRef<string>("");
  useEffect(() => {
    passwordRef.current = password;
  }, [password]);

  function onKeyDownTab() {
    if (HengeIDRef.current === "") {
      HengeIDInputRef.current?.focus();
      return;
    }

    passwordInputRef.current?.focus();
  }

  function onClickLogin() {
    if (HengeIDRef.current === "") {
      HengeIDInputRef.current?.focus();
      return;
    }
    if (passwordRef.current === "") {
      passwordInputRef.current?.focus();
      return;
    }

    signIn("henge-native", {
      HengeID: HengeIDRef.current,
      password: passwordRef.current,
      redirect: false,
    }).then((res) => {
      if (res?.ok) {
        window.dataLayer.push(
          GA4_CUSTOM_TRIGGER.Fetch.Log_In(authMethod, authProvider)
        );

        if (onLogIn) {
          onLogIn();
        } else {
          router.push("/home");
        }
      } else if (res?.error) {
        terminate();
        try {
          const error: HResponse = JSON.parse(res.error);

          const apiResult: ApiResultInterface =
            ApiResultEnum.fromCode(error.code) ?? ApiResultEnum.ERROR_SYSTEM;

          if (apiResult.code === ApiResultEnum.NOT_VERIFIED_EMAIL.code) {
            router.push(
              {
                pathname: "/signup/verification",
                query: {
                  token: (error as NotVerifiedVerificationResponse).data.token,
                },
              },
              "/signup/verification"
            );
          } else {
            showPopupError(apiResult.localedMessages.ENG.popupMessageList);
          }
        } catch (e) {
          terminate();
          showPopupError();
          void e;
        }
      } else {
        terminate();
        showPopupError();
      }
    });
  }

  const isLoginDisabled = !HengeID || !password;

  return (
    <div className="flex flex-col w-full gap-3">
      <AnimatePresence>
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          css={css({
            display: "flex",
            flexFlow: "column nowrap",
            gap: "12px",
            width: "100%",
            overflow: "hidden",
          })}
        >
          <HUI.Input
            ref={HengeIDInputRef}
            kind={HENGE.INPUT_KIND.filled}
            type={HENGE.INPUT_VALUE.text}
            fullWidth
            value={HengeID}
            setValue={setHengeID}
            placeholder={"Username or email address"}
            autoFocus
            onKeyDownTab={onKeyDownTab}
            preventDefaultTab
            onKeyDownEnter={() => onClickLogin()}
          />
          <HUI.Input
            ref={passwordInputRef}
            kind={HENGE.INPUT_KIND.filled}
            type={HENGE.INPUT_VALUE.password}
            fullWidth
            value={password}
            setValue={setPassword}
            placeholder={"Password"}
            onKeyDownEnter={() => onClickLogin()}
          />
        </motion.div>
        {/* {authMethod === AUTH_METHOD.NATIVE &&
          authProvider === AUTH_PROVIDER.HENGE && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              css={css({
                display: "flex",
                flexFlow: "column nowrap",
                gap: "12px",
                width: "100%",
                overflow: "hidden",
              })}
            >
              <HUI.Input
                ref={HengeIDInputRef}
                kind={HENGE.INPUT_KIND.filled}
                type={HENGE.INPUT_VALUE.text}
                fullWidth
                value={HengeID}
                setValue={setHengeID}
                placeholder={"Username or email address"}
                autoFocus
                onKeyDownTab={onKeyDownTab}
                preventDefaultTab
                onKeyDownEnter={() => onClickLogin()}
              />
              <HUI.Input
                ref={passwordInputRef}
                kind={HENGE.INPUT_KIND.filled}
                type={HENGE.INPUT_VALUE.password}
                fullWidth
                value={password}
                setValue={setPassword}
                placeholder={"Password"}
                onKeyDownEnter={() => onClickLogin()}
              />
            </motion.div>
          )} */}
      </AnimatePresence>
      <HUI.Button
        kind={HENGE.BUTTON_KIND.filled}
        colour={HENGE.THEME_COLOUR.mono}
        fullWidth
        disabled={isLoginDisabled}
        onClick={() => {
          if (
            member &&
            nextAuthSession.data?.user &&
            nextAuthSession.status === "authenticated"
          ) {
            if (onLogIn) {
              onLogIn();
            } else {
              router.push("/home");
            }
            return;
          }
          setAuthMethod(AUTH_METHOD.NATIVE);
          setAuthProvider(AUTH_PROVIDER.HENGE);
          onClickLogin();

          // if (
          //   authMethod === AUTH_METHOD.NATIVE &&
          //   authProvider === AUTH_PROVIDER.HENGE
          // ) {
          //   onClickLogin();
          // } else {
          //   setAuthMethod(AUTH_METHOD.NATIVE);
          //   setAuthProvider(AUTH_PROVIDER.HENGE);
          // }
        }}
      >
        Log in
        {/* {authMethod === AUTH_METHOD.NATIVE &&
        authProvider === AUTH_PROVIDER.HENGE
          ? "Log in"
          : // continueButtonMessage
            "Continue with Henge ID"} */}
      </HUI.Button>
    </div>
  );
};
