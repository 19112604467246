import { create } from "zustand";
import { createJWT } from "@/utils/auth/jwt";
import { HResponse } from "@/types/api/response";

export interface OAuthStoreInterface {
  securityToken: string | null;
  securityCode: string | null;
  generateTokensResponse: HResponse | null;
  oauthStoreActions: {
    generateSecurityToken: (payload: string) => Promise<string>;
    setSecurityToken: (securityToken: string | null) => void;
    updateGenerateTokensResponse: (
      securityCode: string | null,
      generateTokensResponse: HResponse | null,
    ) => void;
  };
}

export const OAuthStore = create<OAuthStoreInterface>((set) => ({
  securityToken: null,
  securityCode: null,
  generateTokensResponse: null,
  oauthStoreActions: {
    generateSecurityToken: async (payload) => {
      return createJWT(payload);
    },
    setSecurityToken: (securityToken) => {
      set({ securityToken });
    },
    updateGenerateTokensResponse: (securityCode, generateTokensResponse) => {
      set({ securityCode, generateTokensResponse });
    },
  },
}));

export const useOAuthSecurityToken = () =>
  OAuthStore((state) => state.securityToken);
export const useOAuthSecurityCode = () =>
  OAuthStore((state) => state.securityCode);
export const useOAuthGenerateTokensResponse = () =>
  OAuthStore((state) => state.generateTokensResponse);

export const useOAuthStoreActions = () =>
  OAuthStore((state) => state.oauthStoreActions);
