import { create, StoreApi, UseBoundStore } from "zustand";
import { Member } from "@/classes/member/Member";
import {
  createSocketSessionStore,
  SocketSessionStoreInterface,
} from "@/stores/zustand/session/useSocketSessionStore";
import {
  createCursorStore,
  CursorStoreInterface,
} from "@/stores/zustand/useCursorStore";
import { HengeAuthTokens } from "@/classes/auth/Auth";
import { SessionContextValue } from "next-auth/react";
import { useShallow } from "zustand/react/shallow";
import { terminateRefreshTokenCSR } from "@/utils/auth/nextAuthCookieUtil";
import { LibraryData } from "@/types/data-types";
import { fetchGetLibraryListAPI } from "@/utils/api/fetchWithHengeAuthAPIs";
import { ApiResultEnum } from "@/types/api-result";

export interface MemberStoreInterface {
  member: Member;
  nextAuthSession: SessionContextValue;
  authTokens:
    | {
        accessToken0: HengeAuthTokens["accessToken"] | undefined;
        accessToken: HengeAuthTokens["accessToken"];
        refreshToken: HengeAuthTokens["refreshToken"];
      }
    | undefined;
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>>;
  socketSessionStore: UseBoundStore<StoreApi<SocketSessionStoreInterface>>;
  libraryList: LibraryData[];
  memberStoreActions: {
    updateMember: (member: Member) => void;
    terminate: () => void;
    updateNextAuthSession: (nextAuthSession: SessionContextValue) => void;
    initAuthTokens: (authTokens: HengeAuthTokens) => void;
    updateAccessToken: (accessToken: HengeAuthTokens["accessToken"]) => void;
    refreshLibraryData: (authTokens: HengeAuthTokens | undefined) => void;
  };
}

const memberStore = create<MemberStoreInterface>((set, get) => ({
  member: null as unknown as Member,
  nextAuthSession: {
    update: async () => null,
    data: null,
    status: "loading",
  },
  authTokens: undefined,
  cursorStore: createCursorStore(),
  socketSessionStore: createSocketSessionStore(),
  libraryList: [],
  memberStoreActions: {
    updateMember: (member) => {
      set(() => ({
        member,
      }));
    },
    terminate: () => {
      terminateRefreshTokenCSR();
      get().cursorStore.getState().reset();
      set(() => ({ member: null as unknown as Member }));
    },
    updateNextAuthSession: (nextAuthSession) => {
      set(() => ({ nextAuthSession }));
    },
    initAuthTokens: (authTokens) => {
      if (get().authTokens?.accessToken && get().authTokens?.refreshToken) {
        return;
      }

      set(() => ({
        authTokens: { accessToken0: undefined, ...authTokens },
      }));
    },
    updateAccessToken: (accessToken) => {
      const authTokensState = get().authTokens;

      if (
        !authTokensState ||
        (authTokensState.accessToken0 &&
          authTokensState.accessToken0.value === accessToken.value) ||
        authTokensState.accessToken.value === accessToken.value
      ) {
        return;
      }

      set((state) => ({
        authTokens: {
          accessToken0:
            state.authTokens?.accessToken ?? authTokensState.accessToken,
          accessToken,
          refreshToken:
            state.authTokens?.refreshToken ?? authTokensState.refreshToken,
        },
      }));
    },
    refreshLibraryData: (authTokens: HengeAuthTokens | undefined) => {
      if (!authTokens) return;

      fetchGetLibraryListAPI({}, authTokens).then((res) => {
        if (res.code === ApiResultEnum.OK.code) {
          set({ libraryList: res.data });
        }
      });
    },
  },
}));

export default memberStore;
export const useMember = () => memberStore((state) => state.member);
export const useMemberNextAuthSession = () =>
  memberStore((state) => state.nextAuthSession);
export const useMemberAuthTokens = () =>
  memberStore(
    useShallow((state) =>
      state.authTokens
        ? {
            accessToken: state.authTokens.accessToken,
            refreshToken: state.authTokens.refreshToken,
          }
        : undefined,
    ),
  );
export const useMemberCursorStore = () =>
  memberStore((state) => state.cursorStore);
export const useMemberSocketSessionStore = () =>
  memberStore((state) => state.socketSessionStore);
export const useLibrary = () => memberStore((state) => state.libraryList);
export const useMemberStoreActions = () =>
  memberStore((state) => state.memberStoreActions);
