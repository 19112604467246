import { css } from "@emotion/react";
import { AnimatePresence, motion, MotionProps } from "framer-motion";

interface CardBodyProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  framerMotionProps?: MotionProps;
  className?: string;
}

export const CardBody = ({
  children,
  style,
  framerMotionProps,
  ...rest
}: CardBodyProps) => {
  return (
    <AnimatePresence>
      <motion.div {...rest} style={style} css={bodyCss} {...framerMotionProps}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

const bodyCss = css({
  flexShrink: 1,
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "start",
  alignItems: "center",
  width: "100%",
  overflow: "hidden",
});
