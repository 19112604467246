import { useUtilityObjects } from "@/hooks/useUtilityObjects";
import { useFrame } from "@react-three/fiber";
import { useMemo } from "react";
import * as THREE from "three";
import { calcAutoScale, calcBoundingBox } from "@/utils/hengeUtil";
import { HngVector3 } from "@/utils/threeUtil";

interface HengeLoadingInProcessProps {
  position: HngVector3;
  size: HngVector3;
}

export const HengeLoadingInProcess = ({
  position,
  size,
}: HengeLoadingInProcessProps) => {
  const { loaderInProcessObjectGLTF } = useUtilityObjects();

  const [cloneObject, mixer] = useMemo(() => {
    const object = loaderInProcessObjectGLTF.scene.clone();

    let mixer: THREE.AnimationMixer | null = null;
    if (loaderInProcessObjectGLTF.animations.length > 0) {
      mixer = new THREE.AnimationMixer(object);

      loaderInProcessObjectGLTF.animations.forEach((clip) => {
        if (!mixer) return;

        const action = mixer.clipAction(clip);
        if (!action.isRunning()) {
          action.play();
        }
      });
    }

    if (!object.userData.__processed) {
      object.traverse((o) => {
        o.castShadow = false;
        o.receiveShadow = false;
      });

      const boundingBox = calcBoundingBox(object);
      object.userData.boundingBox = boundingBox;

      const autoScale = calcAutoScale(object);
      object.scale.set(autoScale, autoScale, autoScale);
      object.userData.autoScale = autoScale;

      object.userData.__processed = true;
    }

    return [object, mixer];
  }, []);

  useFrame((state, delta) => {
    if (mixer) {
      mixer.update(delta);
    }
  });

  return (
    <primitive
      object={cloneObject}
      position={[position.x, position.y, position.z]}
      scale={[
        cloneObject.userData.autoScale * size.x,
        cloneObject.userData.autoScale * size.y,
        cloneObject.userData.autoScale * size.z,
      ]}
    />
  );
};
