import { PUBLIC_DIR } from "@/utils/fileUtil";

export const GOOGLE_OAUTH_URI = "https://accounts.google.com/o/oauth2/v2/auth";
export const GOOGLE_OAUTH_RESPONSE_TYPE = "code";
export const GOOGLE_OAUTH_SCOPE_LIST = [
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "openid",
];

export const GOOGLE_OAUTH_TOKEN_URI = "https://oauth2.googleapis.com/token";
export const GOOGLE_OAUTH_GRANT_TYPE = "authorization_code";

export const GOOGLE_OAUTH_POPUP_WIDTH = 480;
export const GOOGLE_OAUTH_POPUP_HEIGHT = 640;

export type GOOGLE_OAUTH_BUTTON_THEME = "light" | "dark";
export type GOOGLE_OAUTH_BUTTON_STATUS =
  | "normal"
  | "focus"
  | "pressed"
  | "disabled";
export type GOOGLE_OAUTH_BUTTON_KIND =
  `${GOOGLE_OAUTH_BUTTON_THEME}_${GOOGLE_OAUTH_BUTTON_STATUS}`;

export const GOOGLE_OAUTH_BUTTON_FILE_DIR = `${PUBLIC_DIR}/logos/google_signin_buttons/web/1x`;
export const GOOGLE_OAUTH_BUTTON_FILE_PREFIX = "btn_google_signin_";
export const GOOGLE_OAUTH_BUTTON_FILE_SUFFIX = "_web.png";

export const GOOGLE_LOGO_FILE_DIR = `${PUBLIC_DIR}/logos/google_signin_buttons/web/vector`;
export const GOOGLE_LOGO_FILE_PREFIX = "btn_google_";
export const GOOGLE_LOGO_FILE_SUFFIX = "_ios.svg";
