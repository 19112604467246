import Viewer from "@/engine/viewer/Viewer";
import { HengePBR } from "@/types/data-types";
import React, { useEffect } from "react";
import { HENGE_SCALE_CONSTANT } from "@/utils/hengeUtil";
import { createEmptyGaiaStore } from "@/engine/viewer/core/useGaiaStoreContainer";
import {
  createEmptyViewerStore,
  useViewerR3fRootStore,
} from "@/engine/viewer/core/useViewerStore";
import { HengeCameraControls } from "@/utils/threeUtil";
import {
  calcZoom,
  DEFAULT_AZIMUTH_ANGLE,
  DEFAULT_POLAR_ANGLE,
  DRAGGING_SMOOTH_TIME,
  GAIA_CAMERA_POSITION,
  GAIA_FOV,
  HENGE_ORTHOGRAPHIC_MAX_DISTANCE_CONSTANT,
  HENGE_ORTHOGRAPHIC_ZOOM_CONSTANT,
  SMOOTH_TIME,
} from "@/utils/cameraUtil";
import { HengeErrorFallback } from "@/engine/henge/fallback/HengeErrorFallback";
import HengeLoaderErrorBoundary from "@/engine/henge/fallback/HengeLoaderErrorBoundary";
import withCSR from "@/components/HOC/withCSR";
import { HengeLoadingSuspense } from "@/engine/henge/fallback/HengeLoadingSuspense";
import CameraControlsStdlib from "camera-controls";
import * as THREE from "three";
import { ShadowPlane } from "@/engine/gaia/utility/ShadowPlane";
import {
  GAIA_AMBIENT_LIGHT_COLOR,
  GAIA_AMBIENT_LIGHT_INTENSITY,
  GAIA_AMBIENT_LIGHT_INTENSITY_CONSTANT,
  GAIA_DIRECTIONAL_LIGHT_INTENSITY,
  GAIA_DIRECTIONAL_LIGHT_INTENSITY_CONSTANT,
} from "@/utils/gaiaUtil";

const PBRSphereLazyComponent = React.lazy(
  () => import("@/engine/henge/PBRSphere"),
);

const viewerStore = createEmptyViewerStore();
const gaiaStore = createEmptyGaiaStore(viewerStore);

export const PBRCaptureViewer = withCSR(
  ({
    capture = false,
    controls = true,
    basecolorMap,
    normalMap,
    heightMap,
    roughnessMap,
    metallicMap,
    aoMap,
    emissiveMap,
  }: HengePBR & { capture?: boolean; controls?: boolean }) => {
    const gaiaLinkedViewerStore = gaiaStore(
      (state) => state.gaiaLinkedViewerStore,
    );
    const cameraControls = useViewerR3fRootStore(
      gaiaLinkedViewerStore,
      (state) => state.controls as unknown as HengeCameraControls,
    );
    const size = useViewerR3fRootStore(
      gaiaLinkedViewerStore,
      (state) => state.size,
    );

    useEffect(() => {
      if (!cameraControls) return;

      cameraControls.mouseButtons.left = controls
        ? CameraControlsStdlib.ACTION.ROTATE
        : CameraControlsStdlib.ACTION.NONE;
      cameraControls.touches.one = controls
        ? CameraControlsStdlib.ACTION.TOUCH_ROTATE
        : CameraControlsStdlib.ACTION.NONE;
      cameraControls.mouseButtons.right = controls
        ? CameraControlsStdlib.ACTION.OFFSET
        : CameraControlsStdlib.ACTION.NONE;
      cameraControls.touches.three = controls
        ? CameraControlsStdlib.ACTION.TOUCH_OFFSET
        : CameraControlsStdlib.ACTION.NONE;
      cameraControls.mouseButtons.wheel = controls
        ? CameraControlsStdlib.ACTION.ZOOM
        : CameraControlsStdlib.ACTION.NONE;
      cameraControls.mouseButtons.middle = controls
        ? CameraControlsStdlib.ACTION.ZOOM
        : CameraControlsStdlib.ACTION.NONE;
      cameraControls.touches.two = controls
        ? CameraControlsStdlib.ACTION.TOUCH_ZOOM
        : CameraControlsStdlib.ACTION.NONE;

      const __gaiaData = gaiaStore.getState().gaiaData;

      const gaiaZoom = calcZoom(cameraControls, __gaiaData, size, true);
      cameraControls.camera.zoom = gaiaZoom;
      let hengeZoom = gaiaZoom * HENGE_ORTHOGRAPHIC_ZOOM_CONSTANT;
      if (capture) {
        hengeZoom *= 0.75;
      }
      cameraControls.zoomTo(hengeZoom, false);

      cameraControls.mouseButtons.left = CameraControlsStdlib.ACTION.ROTATE;
      cameraControls.touches.one = CameraControlsStdlib.ACTION.TOUCH_ROTATE;
      cameraControls.mouseButtons.right = CameraControlsStdlib.ACTION.OFFSET;
      cameraControls.touches.three = CameraControlsStdlib.ACTION.TOUCH_OFFSET;
      cameraControls.mouseButtons.wheel = CameraControlsStdlib.ACTION.ZOOM;
      cameraControls.mouseButtons.middle = CameraControlsStdlib.ACTION.ZOOM;
      cameraControls.touches.two = CameraControlsStdlib.ACTION.TOUCH_ZOOM;

      cameraControls.smoothTime = SMOOTH_TIME;
      cameraControls.draggingSmoothTime = DRAGGING_SMOOTH_TIME;

      cameraControls.minPolarAngle = 0;
      cameraControls.maxPolarAngle = Math.PI;
      cameraControls.minAzimuthAngle = -Infinity;
      cameraControls.maxAzimuthAngle = Infinity;

      cameraControls.maxDistance = HENGE_ORTHOGRAPHIC_MAX_DISTANCE_CONSTANT;

      (cameraControls.camera as THREE.PerspectiveCamera).fov = GAIA_FOV;
      cameraControls.camera.updateProjectionMatrix();

      //================================================================

      const promises: Promise<void>[] = [];

      //

      const target = {
        x: 0,
        y: 0,
        z: 0,
      };
      if (!capture) {
        target.y += HENGE_SCALE_CONSTANT / 2;
      }

      const positionToTarget = GAIA_CAMERA_POSITION;

      //

      promises.push(
        cameraControls.setLookAt(
          positionToTarget.x + target.x,
          positionToTarget.y + target.y,
          positionToTarget.z + target.z,
          target.x,
          target.y,
          target.z,
          false,
        ),
      );

      promises.push(cameraControls.setFocalOffset(0, 0, 0, false));

      promises.push(
        cameraControls.rotateTo(
          DEFAULT_AZIMUTH_ANGLE,
          DEFAULT_POLAR_ANGLE,
          false,
        ),
      );

      Promise.all(promises);
    }, [cameraControls]);

    return (
      <Viewer
        gaiaLinkedViewerStore={gaiaLinkedViewerStore}
        lights={
          <group name={"SceneLightGroup"}>
            <ambientLight
              args={[
                GAIA_AMBIENT_LIGHT_COLOR,
                GAIA_AMBIENT_LIGHT_INTENSITY_CONSTANT *
                  GAIA_AMBIENT_LIGHT_INTENSITY,
              ]}
              name={"DefaultAmbientLight"}
            />
            <directionalLight
              args={[
                GAIA_AMBIENT_LIGHT_COLOR,
                GAIA_DIRECTIONAL_LIGHT_INTENSITY_CONSTANT *
                  GAIA_DIRECTIONAL_LIGHT_INTENSITY,
              ]}
              position={[0, 3, 0]}
              castShadow
              shadow-camera-left={-8}
              shadow-camera-top={8}
              shadow-camera-right={8}
              shadow-camera-bottom={-8}
              shadow-camera-near={0.1}
              shadow-camera-far={20}
              shadow-mapSize-width={4096}
              shadow-mapSize-height={4096}
              name={"DefaultShadowLight"}
            />
          </group>
        }
      >
        {capture ? (
          <PBRSphereLazyComponent
            basecolorMap={basecolorMap}
            normalMap={normalMap}
            heightMap={heightMap}
            roughnessMap={roughnessMap}
            metallicMap={metallicMap}
            aoMap={aoMap}
            emissiveMap={emissiveMap}
            loadingDelayOver={true}
          />
        ) : (
          <HengeLoaderErrorBoundary
            fallbackComponent={
              <HengeErrorFallback
                id={0}
                position={{ x: 0, y: 0, z: 0 }}
                size={{ x: 1, y: 1, z: 1 }}
              />
            }
          >
            <HengeLoadingSuspense
              LazyComponent={PBRSphereLazyComponent}
              props={{
                basecolorMap,
                normalMap,
                heightMap,
                roughnessMap,
                metallicMap,
                aoMap,
                emissiveMap,
              }}
              id={-1}
              position={{ x: 0, y: 0, z: 0 }}
              rotation={{ x: 0, y: 0, z: 0 }}
              size={{ x: 1, y: 1, z: 1 }}
            />
          </HengeLoaderErrorBoundary>
        )}
        <ShadowPlane visible={true} />
      </Viewer>
    );
  },
);
