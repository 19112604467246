import { GTM_CLASS } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import HENGE from "@henge-inc/global-types";
import HUI, { ButtonProps } from "@henge-inc/ui/v1_2";
import { useRouter } from "next/router";

export const ForgotPasswordPageTextButton = ({
  kind = HENGE.BUTTON_KIND.borderless,
  colour = HENGE.BUTTON_COLOUR.gray,
  size = HENGE.DETAILED_SIZE.xSmall,
}: ButtonProps) => {
  const router = useRouter();

  return (
    <HUI.Button
      className={GTM_CLASS.Page_Button.forgot_password_page}
      kind={kind}
      size={size}
      colour={colour}
      onClick={() => {
        router.push("/forgot/password");
      }}
    >
      Forgot password?
    </HUI.Button>
  );
};
