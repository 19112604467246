import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";
import { GaiaStoreInterface } from "@/engine/viewer/core/useGaiaStore";
import { StoreApi, UseBoundStore } from "zustand";
import {
  EmptyViewerStore,
  ViewerState,
} from "@/engine/viewer/core/useViewerStore";
import * as THREE from "three";
import { GaiaParts } from "@/utils/gaiaUtil";
import { GaiaCapsuleData, GaiaData, HengeData } from "@/types/data-types";
import { createGaiaMemberSessionContainer } from "@/stores/zustand/gaia-member/useGaiaMemberSessionContainer";
import { fetchGetGaiaCapsuleListAPI } from "@/utils/api/fetchWithHengeAuthAPIs";
import { HengeAuthTokens } from "@/classes/auth/Auth";
import { HResponse } from "@/types/api/response";
import { ApiResultEnum } from "@/types/api-result";

interface GaiaStoreContainerInterface {
  /** Global cached data */
  hdri: THREE.DataTexture;
  parts: GaiaParts;
  /** gaia capsule list */
  gaiaCapsuleDataList: GaiaCapsuleData[];
  map: Map<number, UseBoundStore<StoreApi<GaiaStoreInterface>>>;
  /** router query */
  viewingOwnerUsername: string;
  viewingGaiaId: number;
  viewingHengeId: number | null;
  viewingHengeData: HengeData | null;
  viewingHengeObject: THREE.Object3D | null;
  /** data for initiating GaiaStore */
  gaiaDataInitiatingGaiaStore: GaiaData | null;
  hengeDataListInitiatingGaiaStore: HengeData[] | null;
  /** current GaiaStore */
  currentGaiaStore: UseBoundStore<StoreApi<GaiaStoreInterface>>;
  /** Actions */
  gaiaStoreContainerActions: {
    initHdri: (hdri: THREE.DataTexture) => void;
    initGaiaParts: (parts: GaiaParts) => void;
    refreshGaiaCapsuleDataList: (
      authTokens: HengeAuthTokens | undefined,
    ) => void;
    updateDataInitiatingGaiaStore: (
      gaiaData: GaiaData,
      hengeDataList: HengeData[],
    ) => void;
    resetDataInitiatingGaiaStore: () => void;
    hasGaiaStore: (id: number) => boolean;
    getGaiaStore: (
      id: number,
    ) => UseBoundStore<StoreApi<GaiaStoreInterface>> | undefined;
    addGaiaStore: (
      id: number,
      gaiaStore: UseBoundStore<StoreApi<GaiaStoreInterface>>,
    ) => void;
    changeViewingOwnerUsername: (username: string) => void;
    changeViewingGaiaId: (viewingGaiaId: number) => void;
    changeViewingHengeId: (viewingHengeId: number | null) => void;
    changeViewingHengeData: (viewingHengeData: HengeData | null) => void;
    changeViewingHengeObject: (
      viewingHengeObject: THREE.Object3D | null,
    ) => void;
    changeViewingGaiaStore: (
      store: UseBoundStore<StoreApi<GaiaStoreInterface>>,
    ) => void;
  };
}

export const createEmptyGaiaStore = (
  gaiaLinkedViewerStore: UseBoundStore<StoreApi<ViewerState>>,
) =>
  create<GaiaStoreInterface>((set) => ({
    gaiaMemberSessionStore: createGaiaMemberSessionContainer(),
    gaiaLinkedViewerStore: gaiaLinkedViewerStore,
    gaiaObject: null as unknown as THREE.Group,
    uploadUnitVisible: false,
    gaiaObjectHoverSpacerIndices: null,
    gaiaObjectUploadingHengeIndices: null,
    trashCanObject: null as unknown as THREE.Group,
    hengeContainer: null as unknown as THREE.Group,
    orbitObject: null as unknown as THREE.Group,
    gaiaData: null as unknown as GaiaData,
    hengeDataList: null as unknown as HengeData[],
    hoverHengeId: null,
    editingHengeId: null,
    uploadingHengeList: [],
    hengeUploadMethod: null,
    generatingPanelOpen: false,
    referenceImageFileGenerateHenge: null,
    generatingHengeUploadObject: false,
    draggingGaiaLibraryObject: null,
    pinnedCellMap: {},
    gaiaStoreActions: {
      setGaiaObject: (object) => void object,
      updateUploadUnitVisible: (uploadUnitVisible) => void uploadUnitVisible,
      updateGaiaObjectHoverSpacerIndices: (gaiaObjectHoverSpacerIndices) =>
        void gaiaObjectHoverSpacerIndices,
      updateGaiaObjectUploadingHengeIndices: (
        gaiaObjectUploadingHengeIndices,
      ) => void gaiaObjectUploadingHengeIndices,
      setTrashCanObject: (object) => void object,
      setHengeContainer: (object) => void object,
      setOrbitObject: (object) => void object,
      refreshGaiaData: (authTokens) => void authTokens,
      refreshHengeDataList: (authTokens) => void authTokens,
      updateHengeData: (updatedHengeData) => updatedHengeData,
      getHengeData: (id) => void id as unknown as HengeData,
      deleteHengeData: (id, authTokens) =>
        void { id, authTokens } as unknown as Promise<HResponse>,
      changeHoverHengeId: (hoverHengeId) => {
        set({ hoverHengeId });
      },
      changeEditingHengeId: (editingHengeId) => void editingHengeId,
      pushUploadingHenge: (uploadingHenge) => void uploadingHenge,
      removeUploadingHenge: (uploadingHenge) => void uploadingHenge,
      updateHengeUploadMethod: (hengeUploadMethod) => void hengeUploadMethod,
      updateGeneratingPanelOpen: (generatingPanelOpen) =>
        void generatingPanelOpen,
      updateReferenceImageFileGenerateHenge: (
        referenceImageFileGenerateHenge,
      ) => void referenceImageFileGenerateHenge,
      updateGeneratingHengeUploadObject: (generatingHengeUploadObject) =>
        void generatingHengeUploadObject,
      updateDraggingGaiaLibraryObject: (draggingGaiaLibraryObject) =>
        void draggingGaiaLibraryObject,
      setPinnedCellMap: (pinnedCellMap) => void pinnedCellMap,
    },
  }));

const EmptyGaiaStore = createEmptyGaiaStore(EmptyViewerStore);

const useGaiaStoreContainer = create<GaiaStoreContainerInterface>(
  (set, get) => ({
    hdri: null as unknown as THREE.DataTexture,
    parts: null as unknown as GaiaParts,
    gaiaCapsuleDataList: [],
    map: new Map(),
    viewingOwnerUsername: null as unknown as string,
    viewingGaiaId: null as unknown as number,
    viewingHengeId: null,
    viewingHengeData: null,
    viewingHengeObject: null,
    gaiaDataInitiatingGaiaStore: null,
    hengeDataListInitiatingGaiaStore: null,
    currentGaiaStore: EmptyGaiaStore,
    gaiaStoreContainerActions: {
      initHdri: (hdri: THREE.DataTexture) =>
        set(() => ({
          hdri: hdri,
        })),
      initGaiaParts: (parts: GaiaParts) =>
        set(() => ({
          parts: parts,
        })),
      refreshGaiaCapsuleDataList: async (authTokens) => {
        if (!authTokens) return;
        if (!get().viewingOwnerUsername) return;

        const gaiaCapsuleDataListRes = await fetchGetGaiaCapsuleListAPI(
          { queryParams: { username: get().viewingOwnerUsername } },
          authTokens,
        );

        if (
          gaiaCapsuleDataListRes.code === ApiResultEnum.OK.code &&
          "data" in gaiaCapsuleDataListRes
        ) {
          set(() => ({ gaiaCapsuleDataList: gaiaCapsuleDataListRes.data }));
        }
      },
      updateDataInitiatingGaiaStore: (gaiaData, hengeDataList) =>
        set(() => ({
          gaiaDataInitiatingGaiaStore: gaiaData,
          hengeDataListInitiatingGaiaStore: hengeDataList,
        })),
      resetDataInitiatingGaiaStore: () =>
        set(() => ({
          gaiaDataInitiatingGaiaStore: null,
          hengeDataListInitiatingGaiaStore: null,
        })),
      hasGaiaStore: (id) => get().map.has(id),
      getGaiaStore: (id) => get().map.get(id),
      addGaiaStore: (id, gaiaStore) =>
        set((state) => ({
          map: new Map(state.map).set(id, gaiaStore),
        })),
      changeViewingOwnerUsername: (username) =>
        set(() => ({
          viewingOwnerUsername: username,
        })),
      changeViewingGaiaId: (viewingGaiaId) =>
        set(() => ({
          viewingGaiaId,
        })),
      changeViewingHengeId: (viewingHengeId) =>
        set(() => ({
          viewingHengeId,
        })),
      changeViewingHengeData: (viewingHengeData) =>
        set(() => ({
          viewingHengeData,
        })),
      changeViewingHengeObject: (viewingHengeObject) =>
        set(() => ({
          viewingHengeObject,
        })),
      changeViewingGaiaStore: (currentGaiaStore) =>
        set(() => ({
          currentGaiaStore: currentGaiaStore,
        })),
    },
  }),
);

export default useGaiaStoreContainer;
export const useHdri = () => useGaiaStoreContainer((state) => state.hdri);
export const useGaiaParts = () => useGaiaStoreContainer((state) => state.parts);
export const useGaiaStoreMap = () =>
  useGaiaStoreContainer((state) => state.map);
export const useViewingOwnerUsername = () =>
  useGaiaStoreContainer((state) => state.viewingOwnerUsername);
export const useGaiaCapsuleDataList = () =>
  useGaiaStoreContainer((state) => state.gaiaCapsuleDataList);
export const useViewingGaiaId = () =>
  useGaiaStoreContainer((state) => state.viewingGaiaId);
export const useDataInitiatingGaiaStore = () =>
  useGaiaStoreContainer(
    useShallow((state) => ({
      gaiaDataInitiatingGaiaStore: state.gaiaDataInitiatingGaiaStore,
      hengeDataListInitiatingGaiaStore: state.hengeDataListInitiatingGaiaStore,
    })),
  );
export const useCurrentGaiaStore = () =>
  useGaiaStoreContainer((state) => state.currentGaiaStore);
export const useViewingHengeId = () =>
  useGaiaStoreContainer((state) => state.viewingHengeId);
export const useViewingHengeData = () =>
  useGaiaStoreContainer((state) => state.viewingHengeData);
export const useViewingHengeObject = () =>
  useGaiaStoreContainer((state) => state.viewingHengeObject);
export const useGaiaStoreContainerActions = () =>
  useGaiaStoreContainer((state) => state.gaiaStoreContainerActions);
