import { SignupPageButton } from "@/components/buttons/SignupPageButton";
import { ForgotPasswordPageTextButton } from "@/components/buttons/text-button/ForgotPasswordPageTextButton";
import { LoginWithNative } from "@/components/login/strategy/native/LoginWithNative";
import { LoginWithGoogleOAuth } from "@/components/login/strategy/oauth/google/LoginWithGoogleOAuth";
import { AuthMethod, AuthProvider } from "@/types/common-types";
import HENGE from "@henge-inc/global-types";
import React, { useState } from "react";

export interface LoginStrategyProps {
  authMethod: AuthMethod | null;
  setAuthMethod: React.Dispatch<React.SetStateAction<AuthMethod | null>>;
  authProvider: AuthProvider | null;
  setAuthProvider: React.Dispatch<React.SetStateAction<AuthProvider | null>>;
  onLogIn?: () => void;
}

export const LoginStrategyMap = ({ onLogIn }: { onLogIn?: () => void }) => {
  const [authMethod, setAuthMethod] = useState<AuthMethod | null>(null);
  const [authProvider, setAuthProvider] = useState<AuthProvider | null>(null);

  return (
    <div className="flex flex-col flex-nowrap justify-center items-center gap-6 w-full">
      <LoginWithNative
        authMethod={authMethod}
        setAuthMethod={setAuthMethod}
        authProvider={authProvider}
        setAuthProvider={setAuthProvider}
        onLogIn={onLogIn}
      />
      {/* <Divider /> */}
      <div className="flex gap-1 w-full flex-nowrap justify-center items-center">
        <SignupPageButton
          kind={HENGE.BUTTON_KIND.borderless}
          colour={HENGE.BUTTON_COLOUR.gray}
          size={HENGE.DETAILED_SIZE.xSmall}
        />
        <div className="w-[1px] h-2 bg-[var(--gray100)]" />
        <ForgotPasswordPageTextButton />
      </div>
      <LoginWithGoogleOAuth
        authMethod={authMethod}
        setAuthMethod={setAuthMethod}
        authProvider={authProvider}
        setAuthProvider={setAuthProvider}
        onLogIn={onLogIn}
      />
    </div>
  );
};
