import HUI from "@henge-inc/ui/v1_2";
import useGlobalStore from "@/stores/zustand/useGlobalStore";
import HENGE from "@henge-inc/global-types";
import Image from "next/image";
import { Card } from "@/components/card/Card";
import { CardBody } from "@/components/card/body/CardBody";
import { LOGIN_CARD_CONTENT_FRAMER_MOTION } from "@/components/login/login-constants";
import { LoginStrategyMap } from "@/components/login/strategy/map/LoginStrategyMap";
import { ModalPortal } from "@/components/modal/ModalPortal";

export const LoginModal = () => {
  const gridRuleset: HENGE.GridRuleType[] = [
    {
      minWidth: 0,
      maxWidth: 600,
      gridColumn: "1 / -1",
      gridRow: "2 / -2",
      style: {
        borderRadius: 0,
      },
    },
    {
      minWidth: 600,
      maxWidth: 1024,
      gridColumn: "4 / -4",
      gridRow: "2 / -2",
      style: {
        borderRadius: "8px",
      },
    },
    {
      minWidth: 1024,
      maxWidth: 1440,
      gridColumn: "7 / -7",
      gridRow: "2 / -2",
      style: {
        borderRadius: "8px",
      },
    },
    {
      minWidth: 1440,
      maxWidth: Number.MAX_SAFE_INTEGER,
      gridColumn: "10 / -10",
      gridRow: "2 / -2",
      style: {
        borderRadius: "8px",
      },
    },
  ];

  const open = useGlobalStore((state) => state.loginModalOpen);
  const { closeLoginModalOpen } = useGlobalStore(
    (state) => state.globalActions,
  );

  return (
    <ModalPortal>
      <HUI.Modal
        gridRuleset={gridRuleset}
        header={<></>}
        body={
          <div
            className={
              "w-full h-full flex flex-col justify-center items-center"
            }
          >
            <div
              className={
                "min-w-max w-full max-w-full h-[80%] max-h-full p-12 flex flex-col justify-center items-center bg-[var(--background)] rounded-3xl overflow-hidden"
              }
            >
              <div
                className={
                  "w-auto h-auto mb-8 flex justify-center items-center"
                }
              >
                <Image
                  src="/assets/logos/symbol-logo.png"
                  width={72}
                  height={72}
                  alt="Henge"
                />
              </div>
              <div className="w-full max-w-[480px]">
                <Card className="w-full flex">
                  <div className="w-full py-8 max-w-[320px] flex flex-col gap-8">
                    <CardBody
                      framerMotionProps={LOGIN_CARD_CONTENT_FRAMER_MOTION}
                    >
                      <LoginStrategyMap onLogIn={closeLoginModalOpen} />
                    </CardBody>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        }
        footer={<></>}
        opener={open}
        setOpener={closeLoginModalOpen}
      />
    </ModalPortal>
  );
};
