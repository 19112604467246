import { css } from "@emotion/react";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const Card = ({ children, ...rest }: CardProps) => {
  return (
    <div css={cardCss} {...rest}>
      {children}
    </div>
  );
};

const cardCss = css({
  userSelect: "none",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  // overflow: "hidden",
});
