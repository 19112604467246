import React, { useEffect } from "react";

const withCSR = <P extends Record<string, any>>(
  Component: React.FC<P>,
): React.FC<P> => {
  const CSR: React.FC<P> = (props: P) => {
    const [isCSR, setIsCSR] = React.useState(false);

    useEffect(() => {
      setIsCSR(true);
    }, []);

    return isCSR ? <Component {...props} /> : <></>;
  };

  return CSR;
};

export default withCSR;
