import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export const useUtilityObjects = () => {
  //================================================================
  // Gaia
  //================================================================

  const trashCanObjectGLTF = useLoader(
    GLTFLoader,
    `${process.env.ASSET_URL ?? ""}/assets/models/utility/gaia/TrashCan.glb`,
  );

  //================================================================
  // Henge
  //================================================================

  // fallback/err

  const errorObjectGLTF = useLoader(
    GLTFLoader,
    `${process.env.ASSET_URL ?? ""}/assets/models/utility/henge/fallback/error/error.glb`,
  );

  // loader/in-process

  const loaderInProcessObjectGLTF = useLoader(
    GLTFLoader,
    `${process.env.ASSET_URL ?? ""}/assets/models/utility/henge/loader/in-process/loading.glb`,
  );

  //================================================================

  return {
    trashCanObjectGLTF,
    errorObjectGLTF,
    loaderInProcessObjectGLTF,
  };
};
