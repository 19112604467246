import * as THREE from "three";
import { create, StoreApi, UseBoundStore } from "zustand";
import { GAIA_BACKGROUND_COLOR, GAIA_FOV } from "@/utils/cameraUtil";
import { RootState } from "@react-three/fiber";
import { HengeCameraType } from "@/types/data-types";
import {
  BACKGROUND_INTENSITY,
  GAIA_BRIGHTNESS,
  GAIA_CONTRAST,
  GAIA_TONE_MAPPING_EXPOSURE,
} from "@/utils/gaiaUtil";

export interface RigPoint {
  x: number;
  y: number;
  id: string;
  label: string;
}
export interface RigPointMarkers {
  chin: { x: number; y: number; z: number };
  left_wrist: { x: number; y: number; z: number };
  right_wrist: { x: number; y: number; z: number };
  left_elbow: { x: number; y: number; z: number };
  right_elbow: { x: number; y: number; z: number };
  left_knee: { x: number; y: number; z: number };
  right_knee: { x: number; y: number; z: number };
  groin: { x: number; y: number; z: number };
}

export interface R3fRootState {
  gl: RootState["gl"];
  camera: RootState["camera"];
  scene: RootState["scene"];
  raycaster: RootState["raycaster"];
  clock: RootState["clock"];
  events: RootState["events"];
  xr: RootState["xr"];
  controls: RootState["controls"];
  pointer: RootState["pointer"];
  size: RootState["size"];
  viewport: RootState["viewport"];
}

export interface ViewerGaiaState {
  rootStateInitiated: boolean;
  toneMappingExposure: number;
  backgroundIntensity: number;
  sceneLightColor: string;
  brightness: number;
  contrast: number;
  hengeLightListVisible: boolean;
  editingHengeLight: boolean;
  cameraType: HengeCameraType;
  fov: number;
  zoomMultiplier: number;
  focalOffset: [number, number, number, boolean];
  frameMode: boolean;
  frameRotation: THREE.Euler;
  frameSize: { x: number; y: number };
  rigPoints: RigPoint[];
  generateRigTrigger: boolean;
  exportHengeId: number | null;
  exportExtension: "glb" | "fbx" | undefined;
  selectedLayerPath: string | null;
  viewerActions: {
    initiateRootState: (rootState: R3fRootState) => void;
    changeToneMappingExposure: (toneMappingExposure: number) => void;
    changeBackgroundIntensity: (backgroundIntensity: number) => void;
    changeSceneLightColor: (sceneLightColor: string) => void;
    changeBrightness: (brightness: number) => void;
    changeContrast: (contrast: number) => void;
    changeHengeLightListVisible: (hengeLightListVisible: boolean) => void;
    changeEditingHengeLight: (editingHengeLight: boolean) => void;
    changeCameraType: (cameraType: HengeCameraType) => void;
    changeFov: (fov: number) => void;
    changeZoomMultiplier: (zoomMultiplier: number) => void;
    changeFocalOffset: (focalOffset: [number, number, number, boolean]) => void;
    toggleFrameMode: (frameMode: boolean) => void;
    rotateFrameAzimuthAngle: () => THREE.Euler;
    updateRigPoints: (rigPoints: RigPoint[]) => void;
    updateFrameSize: (frameSize: { x: number; y: number }) => void;
    updateGenerateRigTrigger: (generateRigTrigger: boolean) => void;
    setExportHenge: (
      exportHengeId: number | null,
      exportExtension: "glb" | "fbx" | undefined,
    ) => void;
    updateSelectedLayerPath: (selectedLayerPath: string | null) => void;
  };
}

export type ViewerState = R3fRootState & ViewerGaiaState;

export const createEmptyViewerStore = () =>
  create<ViewerState>((set, get) => ({
    gl: null as unknown as RootState["gl"],
    camera: null as unknown as RootState["camera"],
    scene: null as unknown as RootState["scene"],
    raycaster: null as unknown as RootState["raycaster"],
    clock: null as unknown as RootState["clock"],
    events: null as unknown as RootState["events"],
    xr: null as unknown as RootState["xr"],
    controls: null as unknown as RootState["controls"],
    pointer: null as unknown as RootState["pointer"],
    size: null as unknown as RootState["size"],
    viewport: null as unknown as RootState["viewport"],
    rootStateInitiated: false,
    toneMappingExposure: GAIA_TONE_MAPPING_EXPOSURE,
    backgroundIntensity: BACKGROUND_INTENSITY,
    sceneLightColor: GAIA_BACKGROUND_COLOR,
    brightness: GAIA_BRIGHTNESS,
    contrast: GAIA_CONTRAST,
    hengeLightListVisible: true,
    editingHengeLight: false,
    cameraType: "Orthographic",
    fov: GAIA_FOV,
    zoomMultiplier: 1,
    focalOffset: [0, 0, 0, false],
    frameMode: false,
    frameRotation: new THREE.Euler(),
    frameSize: { x: 0, y: 0 },
    rigPoints: [
      {
        x: 0,
        y: 0.62,
        id: "chin",
        label: "Chin",
      },
      {
        x: -0.635,
        y: -0.01,
        id: "right_wrist",
        label: "Wrist",
      },
      {
        x: 0.635,
        y: -0.01,
        id: "left_wrist",
        label: "Wrist",
      },
      {
        x: -0.54,
        y: 0.24,
        id: "right_elbow",
        label: "Elbow",
      },
      {
        x: 0.54,
        y: 0.24,
        id: "left_elbow",
        label: "Elbow",
      },
      {
        x: -0.3,
        y: -0.335,
        id: "right_knee",
        label: "Knee",
      },
      {
        x: 0.3,
        y: -0.335,
        id: "left_knee",
        label: "Knee",
      },
      {
        x: 0,
        y: 0.035,
        id: "groin",
        label: "Groin",
      },
    ],
    generateRigTrigger: false,
    exportHengeId: null,
    exportExtension: undefined,
    selectedLayerPath: null,
    viewerActions: {
      initiateRootState: (rootState) =>
        set(() => ({ ...rootState, rootStateInitiated: true })),
      changeToneMappingExposure: (toneMappingExposure) =>
        set(() => ({ toneMappingExposure })),
      changeBackgroundIntensity: (backgroundIntensity) =>
        set(() => ({ backgroundIntensity })),
      changeSceneLightColor: (sceneLightColor) =>
        set(() => ({ sceneLightColor })),
      changeBrightness: (brightness) => set(() => ({ brightness })),
      changeContrast: (contrast) => set(() => ({ contrast })),
      changeHengeLightListVisible: (hengeLightListVisible) =>
        set(() => ({ hengeLightListVisible })),
      changeEditingHengeLight: (editingHengeLight) =>
        set(() => ({ editingHengeLight })),
      changeCameraType: (cameraType) => set(() => ({ cameraType })),
      changeFov: (fov) => set(() => ({ fov })),
      changeZoomMultiplier: (zoomMultiplier) => set(() => ({ zoomMultiplier })),
      changeFocalOffset: (focalOffset) => set(() => ({ focalOffset })),
      toggleFrameMode: (frameMode) => set(() => ({ frameMode })),
      rotateFrameAzimuthAngle: () => {
        const rotation = get().frameRotation.clone();
        rotation.set(
          rotation.x,
          (rotation.y + Math.PI / 2) % (2 * Math.PI),
          rotation.z,
        );

        set(() => {
          return {
            frameRotation: rotation,
          };
        });

        return rotation;
      },
      updateRigPoints: (rigPoints) => set({ rigPoints }),
      updateFrameSize: (frameSize) => set({ frameSize }),
      updateGenerateRigTrigger: (generateRigTrigger) =>
        set({ generateRigTrigger }),
      setExportHenge: (exportHengeId, exportExtension) =>
        set({ exportHengeId, exportExtension }),
      updateSelectedLayerPath: (selectedLayerPath) =>
        set({ selectedLayerPath }),
    },
  }));

export const EmptyViewerStore = createEmptyViewerStore();

export function useViewerR3fRootStore<T = R3fRootState>(
  viewerStore: UseBoundStore<StoreApi<ViewerState>>,
  selector: (state: R3fRootState) => T = (state) => state as unknown as T,
) {
  return viewerStore(selector);
}

export function useViewerGaiaStore<T = ViewerGaiaState>(
  viewerStore: UseBoundStore<StoreApi<ViewerState>>,
  selector: (state: ViewerGaiaState) => T = (state) => state as unknown as T,
) {
  return viewerStore(selector);
}
