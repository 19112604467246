import { HengeLoadingInProcess } from "@/engine/henge/fallback/HengeLoadingInProcess";
import React, { useEffect, useState } from "react";
import { useViewingHengeId } from "@/engine/viewer/core/useGaiaStoreContainer";
import { HngVector3 } from "@/utils/threeUtil";

interface HengeLoadingSuspenseProps<T extends React.ComponentType<any>> {
  LazyComponent: React.LazyExoticComponent<
    React.ComponentType<React.ComponentProps<T> & { loadingDelayOver: boolean }>
  >;
  props: React.ComponentProps<T>;
  id: number;
  position: HngVector3;
  rotation: HngVector3;
  size: HngVector3;
}

export function HengeLoadingSuspense<T extends React.ComponentType<any>>({
  LazyComponent,
  props,
  id,
  position,
  // rotation,
  size,
}: HengeLoadingSuspenseProps<T>) {
  const [loadingDelayOver, setLoadingDelayOver] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoadingDelayOver(true), 2500);

    return () => clearTimeout(timer);
  }, []);

  const viewingHengeId = useViewingHengeId();

  return (
    <React.Suspense
      fallback={
        (!viewingHengeId || viewingHengeId === id) && (
          <HengeLoadingInProcess position={position} size={size} />
        )
      }
    >
      {!loadingDelayOver && (!viewingHengeId || viewingHengeId === id) && (
        <HengeLoadingInProcess
          position={position}
          size={viewingHengeId === id ? { x: 1, y: 1, z: 1 } : size}
        />
      )}
      <LazyComponent {...props} loadingDelayOver={loadingDelayOver} />
    </React.Suspense>
  );
}
