import { calcAutoScale } from "@/utils/hengeUtil";
import { useMemo } from "react";
import { useUtilityObjects } from "@/hooks/useUtilityObjects";
import { useViewingHengeId } from "@/engine/viewer/core/useGaiaStoreContainer";
import { HngVector3 } from "@/utils/threeUtil";

interface HengeErrorFallbackProps {
  id: number;
  position: HngVector3;
  size: HngVector3;
}

export const HengeErrorFallback = ({
  id,
  position,
  size,
}: HengeErrorFallbackProps) => {
  const { errorObjectGLTF } = useUtilityObjects();

  const [cloneObject] = useMemo(() => {
    const object = errorObjectGLTF.scene.clone();

    object.traverse((o) => {
      o.castShadow = true;
      o.receiveShadow = true;
    });

    const autoScale = calcAutoScale(object);
    object.scale.set(autoScale, autoScale, autoScale);
    object.userData.autoScale = autoScale;

    return [object];
  }, []);

  const viewingHengeId = useViewingHengeId();

  return (
    <primitive
      object={cloneObject}
      visible={!viewingHengeId || viewingHengeId === id}
      position={[position.x, position.y, position.z]}
      scale={[
        cloneObject.userData.autoScale * size.x,
        cloneObject.userData.autoScale * size.y,
        cloneObject.userData.autoScale * size.z,
      ]}
    />
  );
};
