import HENGE from "@henge-inc/global-types";

export const AUTH_METHOD = {
  NATIVE: "NATIVE",
  OAUTH: "OAUTH",
} as const;
export type AuthMethod = HENGE.ValuesTypeFromObject<typeof AUTH_METHOD>;

const OAUTH_PROVIDER = {
  GOOGLE: "GOOGLE",
} as const;

export const AUTH_PROVIDER = {
  HENGE: "HENGE",
  ...OAUTH_PROVIDER,
} as const;
export type AuthProvider = HENGE.ValuesTypeFromObject<typeof AUTH_PROVIDER>;

export function validateAuthMethodAndProvider(
  authMethod: any,
  authProvider: any,
): boolean {
  if (authMethod === AUTH_METHOD.NATIVE) {
    if (authProvider === AUTH_PROVIDER.HENGE) {
      return true;
    } else {
      return false;
    }
  } else if (authMethod === AUTH_METHOD.OAUTH) {
    if (authProvider === AUTH_PROVIDER.GOOGLE) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const HENGE_SHARE_SCOPE = {
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC",
} as const;
export type HengeShareScope = HENGE.ValuesTypeFromObject<
  typeof HENGE_SHARE_SCOPE
>;
