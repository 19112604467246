import { css } from "@emotion/react";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";
import { motion } from "framer-motion";
import React from "react";

export interface MotionTextProps {
  textStyleProps?: HUI.TextStyleProps;
  from?: HENGE.PositionFaceType;
  delayBetweenWords?: number;
  delayBetweenChars?: number;
  durationChar?: number;
  fadeIn?: boolean;
}

interface Props extends MotionTextProps {
  children: string;
}

export const MotionText = ({
  children,
  textStyleProps,
  from = HENGE.POSITION_FACE.bottom,
  delayBetweenWords = 0.05,
  delayBetweenChars = 0.001,
  durationChar = 0.8,
  fadeIn,
}: Props) => {
  const charInitial = {
    opacity: fadeIn ? 0 : 1,
    transform:
      from === HENGE.POSITION_FACE.left
        ? "translateX(-100%)"
        : from === HENGE.POSITION_FACE.right
          ? "translateX(100%)"
          : from === HENGE.POSITION_FACE.top
            ? "translateY(-100%)"
            : "translateY(100%)",
  };
  const charAnimate = {
    opacity: 1,
    transform:
      from === HENGE.POSITION_FACE.left || from === HENGE.POSITION_FACE.right
        ? "translateX(0)"
        : "translateY(0)",
  };

  const charItem = {
    charInitial,
    charAnimate: {
      ...charAnimate,
      transition: {
        ease: "circIn",
        duration: durationChar,
      },
    },
  };

  const words = children.split(" ");

  let flexJustifyContentByTextAlign: string = HENGE.FLEX_ALIGN.start;
  if (textStyleProps?.align === HENGE.TEXT_ALIGN.center) {
    flexJustifyContentByTextAlign = HENGE.FLEX_ALIGN.center;
  } else if (textStyleProps?.align === HENGE.TEXT_ALIGN.right) {
    flexJustifyContentByTextAlign = HENGE.FLEX_ALIGN.end;
  } else if (textStyleProps?.align === HENGE.TEXT_ALIGN.justify) {
    flexJustifyContentByTextAlign = HENGE.FLEX_ALIGN["space-between"];
  }

  return (
    <span
      css={css({
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: flexJustifyContentByTextAlign,
        alignItems: "start",
      })}
    >
      {words.map((word, wordIndex) => {
        const charContainer = {
          charAnimate: {
            transition: {
              staggerChildren: delayBetweenChars,
              delayChildren: wordIndex * delayBetweenWords,
            },
          },
        };

        return (
          <React.Fragment key={`card-title-word-${word}-${wordIndex}`}>
            <motion.span
              variants={charContainer}
              initial={"charInitial"}
              animate={"charAnimate"}
              css={css({
                flexGrow:
                  flexJustifyContentByTextAlign ===
                    HENGE.FLEX_ALIGN["space-between"] &&
                  wordIndex !== words.length - 1
                    ? 1
                    : 0,
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "center",
                alignItems: "start",
              })}
            >
              {word.split("").map((char, charIndex) => {
                return (
                  <span
                    key={`card-title-char-${char}-${charIndex}`}
                    css={css({
                      flexGrow:
                        flexJustifyContentByTextAlign ===
                        HENGE.FLEX_ALIGN["space-between"]
                          ? 1
                          : 0,
                      overflow: "hidden",
                    })}
                  >
                    <motion.span
                      variants={charItem}
                      css={css({
                        display: "flex",
                        flexFlow: "row nowrap",
                        justifyContent: "start",
                        alignItems: "start",
                      })}
                    >
                      <HUI.Text
                        kind={HENGE.TEXT_KIND.heading2}
                        align={HENGE.TEXT_ALIGN.center}
                        {...textStyleProps}
                      >
                        {char}
                      </HUI.Text>
                    </motion.span>
                  </span>
                );
              })}
            </motion.span>
            {wordIndex !== words.length - 1 && (
              <div
                css={css({
                  flexGrow:
                    flexJustifyContentByTextAlign ===
                    HENGE.FLEX_ALIGN["space-between"]
                      ? 1
                      : 0,
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "center",
                  alignItems: "start",
                })}
              >
                <HUI.Text
                  kind={HENGE.TEXT_KIND.heading2}
                  align={HENGE.TEXT_ALIGN.center}
                  {...textStyleProps}
                >
                  {"\u00A0"}
                </HUI.Text>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </span>
  );
};
