import {
  useViewerGaiaStore,
  ViewerState,
} from "@/engine/viewer/core/useViewerStore";
import { StoreApi, UseBoundStore } from "zustand";

interface ViewerContextProps {
  gaiaLinkedViewerStore: UseBoundStore<StoreApi<ViewerState>>;
  children?: React.ReactNode;
}

export const ViewerContextConsumer = ({
  gaiaLinkedViewerStore,
  children,
}: ViewerContextProps) => {
  const rootStateInitiated = useViewerGaiaStore(
    gaiaLinkedViewerStore,
    (state) => state.rootStateInitiated,
  );

  return rootStateInitiated ? <>{children}</> : <></>;
};
