import {
  GOOGLE_LOGO_FILE_DIR,
  GOOGLE_LOGO_FILE_PREFIX,
  GOOGLE_LOGO_FILE_SUFFIX,
  GOOGLE_OAUTH_BUTTON_KIND,
  GOOGLE_OAUTH_BUTTON_THEME,
} from "@/components/signup/strategy/oauth/google/google-oauth-constants";
import { css } from "@emotion/react";
import HUI from "@henge-inc/ui/v1_2";
import { useEffect, useState } from "react";

interface GoogleOAuthWebButtonProps {
  fullWidth?: boolean;
  onClick?: () => void;
}

export const GoogleOAuthWebButton = ({
  fullWidth = false,
  onClick,
}: GoogleOAuthWebButtonProps) => {
  const theme = HUI.useTheme();
  const [buttonTheme, setButtonTheme] = useState<GOOGLE_OAUTH_BUTTON_THEME>(
    () => (theme.mode === HUI.THEME_MODE_ENUM.day ? "light" : "dark"),
  );
  useEffect(() => {
    if (theme.mode === HUI.THEME_MODE_ENUM.day) {
      setButtonTheme("light");
    } else {
      setButtonTheme("dark");
    }
  }, [theme]);

  const kindNormal: GOOGLE_OAUTH_BUTTON_KIND = `${buttonTheme}_normal`;
  // const kindFocus: GOOGLE_OAUTH_BUTTON_KIND = `${buttonTheme}_focus`;
  // const kindPressed: GOOGLE_OAUTH_BUTTON_KIND = `${buttonTheme}_pressed`;
  // const kindDisabled: GOOGLE_OAUTH_BUTTON_KIND = `${buttonTheme}_disabled`;

  const normalButtonPath = `${GOOGLE_LOGO_FILE_DIR}/${GOOGLE_LOGO_FILE_PREFIX}${kindNormal}${GOOGLE_LOGO_FILE_SUFFIX}`;
  // const focusButtonPath = `${GOOGLE_LOGO_FILE_DIR}/${GOOGLE_LOGO_FILE_PREFIX}${kindFocus}${GOOGLE_LOGO_FILE_SUFFIX}`;
  // const pressedButtonPath = `${GOOGLE_LOGO_FILE_DIR}/${GOOGLE_LOGO_FILE_PREFIX}${kindPressed}${GOOGLE_LOGO_FILE_SUFFIX}`;
  // const disabledButtonPath = `${GOOGLE_LOGO_FILE_DIR}/${GOOGLE_LOGO_FILE_PREFIX}${kindDisabled}${GOOGLE_LOGO_FILE_SUFFIX}`;

  return (
    <button
      css={css`
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: ${fullWidth ? "100%" : "40px"};
        height: 40px;
        border-radius: 8px;

        &:hover {
          background-color: var(--gray50);
        }

        &:enabled {
          cursor: pointer;
          background-color: ${buttonTheme === "light"
            ? "var(--gray50-alpha40)"
            : "#4285f4"};

          & > div > div:first-of-type > img {
            content: url(${normalButtonPath});
          }
        }
      `}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div
        css={css`
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;
          padding: 0 2px;
        `}
      >
        <div
          css={css({
            position: "relative",
            width: "28px",
            height: "28px",
            aspectRatio: "1 / 1",
            borderRadius: "2px",
            overflow: "hidden",
          })}
        >
          <img
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: "36px",
              minWidth: "36px",
              height: "36px",
              minHeight: "36px",
            }}
          />
        </div>
      </div>
    </button>
  );
};
