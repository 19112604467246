import * as THREE from "three";
import { DEFAULT_GAIA_GRID_SIZE, SHADOW_PLANE_OPACITY } from "@/utils/gaiaUtil";

interface ShadowPlaneProps {
  visible: boolean;
}

export const ShadowPlane = ({ visible }: ShadowPlaneProps) => {
  // const tex = new THREE.TextureLoader().load("https://upload.wikimedia.org/wikipedia/commons/4/4c/Grass_Texture.png")
  // tex.anisotropy = 32
  // tex.repeat.set(100, 100)
  // tex.wrapT = THREE.RepeatWrapping
  // tex.wrapS = THREE.RepeatWrapping
  //
  // return (
  //   <mesh
  //     visible={visible}
  //     position={[0, 0, 0]}
  //     rotation={[-Math.PI / 2, 0, 0]}
  //     castShadow={false}
  //     receiveShadow={true}
  //     userData={{ name: "ShadowPlane" }}
  //   >
  //     <planeGeometry args={[10000, 10000]} />
  //     <meshLambertMaterial opacity={SHADOW_PLANE_OPACITY} map={tex} />
  //   </mesh>
  // );

  // return (
  //   <mesh
  //     visible={visible}
  //     position={[0, 0, 0]}
  //     rotation={[-Math.PI / 2, 0, 0]}
  //     castShadow={false}
  //     receiveShadow={true}
  //     userData={{ name: "ShadowPlane" }}
  //   >
  //     <planeGeometry args={[10000, 10000]} />
  //     <shadowMaterial opacity={0.1} />
  //   </mesh>
  // );

  const size = DEFAULT_GAIA_GRID_SIZE * 10;
  const x = 0 - size / 2; // THREE.js x
  const y = 0 - size / 2; // THREE.js -z
  const width = size; // x
  const height = size; // y
  const radius = 0.75;

  const shape = new THREE.Shape();
  shape.moveTo(x, y + radius);
  shape.lineTo(x, y + height - radius);
  shape.quadraticCurveTo(x, y + height, x + radius, y + height);
  shape.lineTo(x + width - radius, y + height);
  shape.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
  shape.lineTo(x + width, y + radius);
  shape.quadraticCurveTo(x + width, y, x + width - radius, y);
  shape.lineTo(x + radius, y);
  shape.quadraticCurveTo(x, y, x, y + radius);

  // const geometry = new THREE.ShapeGeometry(shape);

  // const material = new THREE.ShadowMaterial();
  // material.opacity = SHADOW_PLANE_OPACITY;

  // const shadowPlane = new THREE.Mesh(geometry, material);
  // shadowPlane.userData.name = "ShadowPlane";
  // shadowPlane.visible = visible;
  // shadowPlane.receiveShadow = true;
  // shadowPlane.rotation.set(-Math.PI / 2, 0, 0);

  return (
    <mesh
      rotation={[-Math.PI / 2, 0, 0]}
      castShadow={false}
      receiveShadow={true}
      userData={{ name: "ShadowPlane" }}
    >
      <shapeGeometry args={[shape]} />
      <shadowMaterial
        transparent={true}
        opacity={SHADOW_PLANE_OPACITY}
        visible={visible}
      />
    </mesh>
  );
};
