import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import {
  useViewerGaiaStore,
  ViewerState,
} from "@/engine/viewer/core/useViewerStore";
import { StoreApi, UseBoundStore } from "zustand";

interface ViewerContextProviderProps {
  gaiaLinkedViewerStore: UseBoundStore<StoreApi<ViewerState>>;
}

export const ViewerContextProvider = ({
  gaiaLinkedViewerStore,
}: ViewerContextProviderProps) => {
  const viewerActions = useViewerGaiaStore(
    gaiaLinkedViewerStore,
    (state) => state.viewerActions,
  );
  const { initiateRootState } = viewerActions;

  const r3f = useThree();

  useEffect(() => {
    initiateRootState(r3f);
  }, [r3f]);

  return <></>;
};
