import Viewer from "@/engine/viewer/Viewer";
import { HengeMaterial } from "@/types/data-types";
import React, { useEffect } from "react";
import * as THREE from "three";
import { HENGE_SCALE_CONSTANT } from "@/utils/hengeUtil";
import {
  calcZoom,
  DEFAULT_AZIMUTH_ANGLE,
  DEFAULT_POLAR_ANGLE,
  GAIA_CAMERA_POSITION,
  HENGE_ORTHOGRAPHIC_ZOOM_CONSTANT,
} from "@/utils/cameraUtil";
import {
  createEmptyViewerStore,
  useViewerR3fRootStore,
} from "@/engine/viewer/core/useViewerStore";
import { HengeCameraControls } from "@/utils/threeUtil";
import { createEmptyGaiaStore } from "@/engine/viewer/core/useGaiaStoreContainer";
import { ShadowPlane } from "@/engine/gaia/utility/ShadowPlane";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { useLoader } from "@react-three/fiber";
import CameraControlsStdlib from "camera-controls";
import {
  GAIA_AMBIENT_LIGHT_COLOR,
  GAIA_AMBIENT_LIGHT_INTENSITY,
  GAIA_AMBIENT_LIGHT_INTENSITY_CONSTANT,
  GAIA_DIRECTIONAL_LIGHT_INTENSITY,
  GAIA_DIRECTIONAL_LIGHT_INTENSITY_CONSTANT,
} from "@/utils/gaiaUtil";

const viewerStore = createEmptyViewerStore();
const gaiaStore = createEmptyGaiaStore(viewerStore);

export const MaterialCaptureViewer = ({
  capture = false,
  controls = true,
  ...props
}: HengeMaterial & { capture?: boolean; controls?: boolean }) => {
  const gaiaLinkedViewerStore = gaiaStore(
    (state) => state.gaiaLinkedViewerStore,
  );
  const cameraControls = useViewerR3fRootStore(
    gaiaLinkedViewerStore,
    (state) => state.controls as unknown as HengeCameraControls,
  );
  const size = useViewerR3fRootStore(
    gaiaLinkedViewerStore,
    (state) => state.size,
  );

  useEffect(() => {
    if (!cameraControls) return;

    cameraControls.mouseButtons.left = controls
      ? CameraControlsStdlib.ACTION.ROTATE
      : CameraControlsStdlib.ACTION.NONE;
    cameraControls.touches.one = controls
      ? CameraControlsStdlib.ACTION.TOUCH_ROTATE
      : CameraControlsStdlib.ACTION.NONE;
    cameraControls.mouseButtons.right = controls
      ? CameraControlsStdlib.ACTION.OFFSET
      : CameraControlsStdlib.ACTION.NONE;
    cameraControls.touches.three = controls
      ? CameraControlsStdlib.ACTION.TOUCH_OFFSET
      : CameraControlsStdlib.ACTION.NONE;
    cameraControls.mouseButtons.wheel = controls
      ? CameraControlsStdlib.ACTION.ZOOM
      : CameraControlsStdlib.ACTION.NONE;
    cameraControls.mouseButtons.middle = controls
      ? CameraControlsStdlib.ACTION.ZOOM
      : CameraControlsStdlib.ACTION.NONE;
    cameraControls.touches.two = controls
      ? CameraControlsStdlib.ACTION.TOUCH_ZOOM
      : CameraControlsStdlib.ACTION.NONE;

    const __gaiaData = gaiaStore.getState().gaiaData;

    const gaiaZoom = calcZoom(cameraControls, __gaiaData, size, true);
    cameraControls.camera.zoom = gaiaZoom;
    let hengeZoom = gaiaZoom * HENGE_ORTHOGRAPHIC_ZOOM_CONSTANT;
    if (capture) {
      hengeZoom *= 0.75;
    }
    cameraControls.zoomTo(hengeZoom, false);

    //================================================================

    const promises: Promise<void>[] = [];

    //

    const target = {
      x: 0,
      y: 0,
      z: 0,
    };
    if (!capture) {
      target.y += HENGE_SCALE_CONSTANT / 2;
    }

    const positionToTarget = GAIA_CAMERA_POSITION;

    //

    promises.push(
      cameraControls.setLookAt(
        positionToTarget.x + target.x,
        positionToTarget.y + target.y,
        positionToTarget.z + target.z,
        target.x,
        target.y,
        target.z,
        false,
      ),
    );

    promises.push(cameraControls.setFocalOffset(0, 0, 0, false));

    promises.push(
      cameraControls.rotateTo(
        DEFAULT_AZIMUTH_ANGLE,
        DEFAULT_POLAR_ANGLE,
        false,
      ),
    );

    Promise.all(promises);
  }, [cameraControls]);

  const texture = useLoader(
    RGBELoader,
    `${process.env.ASSET_URL ?? ""}/assets/environments/hdri/studio_small_09_1k.hdr`,
  );

  return (
    <Viewer
      gaiaLinkedViewerStore={gaiaLinkedViewerStore}
      lights={
        <group name={"SceneLightGroup"}>
          <ambientLight
            args={[
              GAIA_AMBIENT_LIGHT_COLOR,
              GAIA_AMBIENT_LIGHT_INTENSITY_CONSTANT *
                GAIA_AMBIENT_LIGHT_INTENSITY,
            ]}
            name={"DefaultAmbientLight"}
          />
          <directionalLight
            args={[
              GAIA_AMBIENT_LIGHT_COLOR,
              GAIA_DIRECTIONAL_LIGHT_INTENSITY_CONSTANT *
                GAIA_DIRECTIONAL_LIGHT_INTENSITY,
            ]}
            position={[0, 3, 0]}
            castShadow
            shadow-camera-left={-8}
            shadow-camera-top={8}
            shadow-camera-right={8}
            shadow-camera-bottom={-8}
            shadow-camera-near={0.1}
            shadow-camera-far={20}
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            name={"DefaultShadowLight"}
          />
        </group>
      }
    >
      <mesh
        castShadow
        receiveShadow
        position={[0, HENGE_SCALE_CONSTANT / 2, 0]}
      >
        <icosahedronGeometry args={[HENGE_SCALE_CONSTANT / 2, 256]} />
        <meshPhysicalMaterial
          {...props}
          side={props.side as THREE.Side}
          thickness={(HENGE_SCALE_CONSTANT / 2) * (props.thickness / 100)}
        />
      </mesh>
      <mesh
        receiveShadow
        position={[
          -HENGE_SCALE_CONSTANT,
          -(HENGE_SCALE_CONSTANT / 2),
          -HENGE_SCALE_CONSTANT,
        ]}
        rotation={[-Math.PI * 0.18, Math.PI * 1.23, -Math.PI * 1.125]}
      >
        <icosahedronGeometry args={[(HENGE_SCALE_CONSTANT / 2) * 0.98, 256]} />
        <meshPhysicalMaterial map={texture} />
      </mesh>
      <ShadowPlane visible={true} />
    </Viewer>
  );
};
