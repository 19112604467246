import { CardBody } from "@/components/card/body/CardBody";
import { Card } from "@/components/card/Card";
import { CardFooter } from "@/components/card/footer/CardFooter";
import { CardTitle } from "@/components/card/header/CardTitle";
import { ModalPortal } from "@/components/modal/ModalPortal";
import { FeedCaptureViewer } from "@/components/viewer/FeedCaptureViewer";
import { MaterialCaptureViewer } from "@/components/viewer/MaterialCaptureViewer";
import { PBRCaptureViewer } from "@/components/viewer/PBRCaptureViewer";
import {
  useLibrary,
  useMember,
  useMemberAuthTokens,
  useMemberStoreActions,
} from "@/stores/zustand/member/useMemberStore";
import useGlobalStore from "@/stores/zustand/useGlobalStore";
import { ApiResultEnum } from "@/types/api-result";
import { HengeMaterial, HengePBR } from "@/types/data-types";
import { fetchAddLibraryAPI } from "@/utils/api/fetchWithHengeAuthAPIs";
import { css } from "@emotion/react";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const DetailCardModal = () => {
  const gridRuleset: HENGE.GridRuleType[] = [
    {
      minWidth: 0,
      maxWidth: 600,
      gridColumn: "1 / -1",
      gridRow: "2 / -2",
      style: {
        borderRadius: 0,
      },
    },
    {
      minWidth: 600,
      maxWidth: 1024,
      gridColumn: "1 / -1",
      gridRow: "4 / -4",
      style: {
        borderRadius: "8px",
      },
    },
    {
      minWidth: 1024,
      maxWidth: 1440,
      gridColumn: "8 / -8",
      gridRow: "4 / -4",
      style: {
        borderRadius: "8px",
      },
    },
    {
      minWidth: 1440,
      maxWidth: Number.MAX_SAFE_INTEGER,
      gridColumn: "10 / -10",
      gridRow: "4 / -4",
      style: {
        borderRadius: "8px",
      },
    },
  ];

  const router = useRouter();
  const member = useMember();
  const authTokens = useMemberAuthTokens();

  const { openLoginModal } = useGlobalStore((state) => state.globalActions);

  const feedItemDetail = useGlobalStore((state) => state.feedItemDetail);
  const open = useGlobalStore((state) => state.feedDetailCardModalOpen);
  const { updateFeedItemDetail, closeFeedDetailCardOpen } = useGlobalStore(
    (state) => state.globalActions
  );

  useEffect(() => {
    if (open) {
      console.log(feedItemDetail);
    }
  }, [open]);

  const library = useLibrary();
  const { refreshLibraryData } = useMemberStoreActions();

  useEffect(() => {
    refreshLibraryData(authTokens);
  }, [authTokens]);

  const alreadySaved =
    library.findIndex(
      (item) => feedItemDetail && feedItemDetail.uid === item.feedData.uid
    ) !== -1;

  const handleGoToFitting = () => {
    router.push("/home").then(() => {
      updateFeedItemDetail(null);
      closeFeedDetailCardOpen();
      // setBannerOpen(false);
    });
  };

  return (
    <ModalPortal>
      <>
        <HUI.Modal
          gridRuleset={gridRuleset}
          header={<></>}
          body={
            <>
              {!!feedItemDetail && (
                <div
                  className={
                    "w-full h-full flex flex-col justify-center items-center"
                  }
                  css={css`
                    @media only screen and (min-width: 820px) {
                      width: 800px;
                    }
                  `}
                >
                  <div
                    className={
                      "w-full h-full flex justify-center items-center bg-[var(--background)] rounded-3xl overflow-hidden"
                    }
                  >
                    <Card className="w-full relative h-full flex">
                      <div className="absolute top-0 w-full p-12 bg-gradient-to-b from-[var(--background)] z-10">
                        <CardTitle
                          textStyleProps={{
                            kind: HENGE.TEXT_KIND.heading3,
                            weight: HENGE.TEXT_WEIGHT.semibold,
                            align: HENGE.TEXT_ALIGN.center,
                            whiteSpace: HENGE.TEXT_WHITE_SPACE.normal,
                            lineHeight: HENGE.TEXT_LINE_HEIGHT.small,
                          }}
                        >
                          {feedItemDetail.name}
                        </CardTitle>
                        <HUI.Text
                          kind={HENGE.TEXT_KIND.body1}
                          weight={HENGE.TEXT_WEIGHT.medium}
                          colour={HENGE.SEMANTIC_TEXT_COLOUR["text-tertiary"]}
                          align={HENGE.TEXT_ALIGN.center}
                          link={
                            feedItemDetail.type === "objaverse-v1" ? (
                              <a
                                href={
                                  JSON.parse(feedItemDetail.data).user
                                    .profileUrl
                                }
                                target={"_blank"}
                                rel="noreferrer"
                              />
                            ) : undefined
                          }
                        >
                          @{feedItemDetail.username}
                        </HUI.Text>
                      </div>

                      <div className={"w-full h-full flex flex-col gap-8"}>
                        <CardBody className="h-full">
                          <div className={"w-full h-full"}>
                            <div
                              className={
                                "w-full h-full rounded-xl overflow-hidden"
                              }
                            >
                              {feedItemDetail.viewerType === "object" && (
                                <FeedCaptureViewer
                                  url={JSON.parse(feedItemDetail.data).hengeUrl}
                                />
                              )}
                              {feedItemDetail.viewerType === "material" && (
                                <MaterialCaptureViewer
                                  {...(JSON.parse(
                                    feedItemDetail.data
                                  ) as HengeMaterial)}
                                />
                              )}
                              {feedItemDetail.viewerType === "pbr" && (
                                <PBRCaptureViewer
                                  {...(JSON.parse(
                                    feedItemDetail.data
                                  ) as HengePBR)}
                                />
                              )}
                            </div>
                          </div>
                        </CardBody>
                      </div>
                      <div className="absolute bottom-0 p-12 bg-gradient-to-t w-full from-[var(--background)]">
                        <CardFooter>
                          <div
                            className={
                              "w-full flex flex-col items-center gap-2 px-6"
                            }
                          >
                            <HUI.Button
                              kind={HENGE.BUTTON_KIND.filled}
                              colour={HENGE.GRAYSCALE_COLOUR.mono}
                              disabled={alreadySaved}
                              fullWidth
                              className="max-w-[320px]"
                              onClick={() => {
                                if (
                                  !member ||
                                  !member.metadata ||
                                  !authTokens
                                ) {
                                  openLoginModal();
                                  return;
                                }

                                if (alreadySaved) {
                                  return;
                                }

                                if (feedItemDetail) {
                                  fetchAddLibraryAPI(
                                    {
                                      json: {
                                        feedUid: feedItemDetail.uid,
                                      },
                                    },
                                    authTokens
                                  ).then((res) => {
                                    if (res.code === ApiResultEnum.OK.code) {
                                      refreshLibraryData(authTokens);
                                      // setBannerOpen(true);
                                    }
                                  });
                                }
                              }}
                            >
                              {alreadySaved ? "Saved" : "Save"}
                            </HUI.Button>
                            {alreadySaved && (
                              <HUI.Button
                                kind={HENGE.BUTTON_KIND.borderless}
                                colour={HENGE.THEME_COLOUR.mono}
                                fullWidth
                                className="max-w-[320px]"
                                onClick={handleGoToFitting}
                              >
                                Go to fitting
                              </HUI.Button>
                            )}
                          </div>
                        </CardFooter>
                      </div>
                    </Card>
                  </div>
                </div>
              )}
            </>
          }
          footer={<></>}
          opener={open}
          setOpener={() => {
            router.push("/feed/home").then(() => {
              updateFeedItemDetail(null);
              closeFeedDetailCardOpen();
              // setBannerOpen(false);
            });
          }}
        />
        {/* <HUI.Banner
          open={bannerOpen}
          position="bottom-center"
          action={
            <HUI.Button
              kind={HENGE.BUTTON_KIND.filled}
              size={HENGE.DETAILED_SIZE.small}
              colour={HENGE.THEME_COLOUR.mono}
              onClick={handleGoToFitting}
            >
              Go to fitting
            </HUI.Button>
          }
        >
          Saved to your library
        </HUI.Banner> */}
      </>
    </ModalPortal>
  );
};
