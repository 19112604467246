import { css } from "@emotion/react";
import { motion, MotionProps } from "framer-motion";

interface CardFooterProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  framerMotionProps?: MotionProps;
}

export const CardFooter = ({
  children,
  style,
  framerMotionProps,
}: CardFooterProps) => {
  return (
    <motion.div style={style} css={footerCss} {...framerMotionProps}>
      {children}
    </motion.div>
  );
};

const footerCss = css({
  flexShrink: 0,
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "end",
  alignItems: "center",
  width: "100%",
});
