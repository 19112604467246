export const LOGIN_CARD_CONTENT_FRAMER_MOTION = {
  initial: {
    opacity: 0,
    transform: "translateY(128px)",
  },
  animate: {
    opacity: 1,
    transform: "translateY(0)",
  },
  transition: {
    ease: "circIn",
    delay: 0.6,
    duration: 0.6,
  },
};
