import { AuthToken, HengeAuthTokens } from "@/classes/auth/Auth";
import { GA4_CUSTOM_TRIGGER } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import memberStore from "@/stores/zustand/member/useMemberStore";
import { ApiResultEnum } from "@/types/api-result";
import { GenerateTokenRequest } from "@/types/api/auth/request/auth-request";
import {
  GenerateTokensResponse,
  NotVerifiedVerificationResponse,
  RefreshAccessTokenResponse,
  UnauthorizedResponse,
} from "@/types/api/auth/response/auth-response";
import {
  FetchInitOptions,
  MakeFetchInitOptionsParameters,
  makeFetchInits,
} from "@/utils/api/fetch-types";
import { signOut } from "next-auth/react";

export const MAX_TIME_REFRESH = 60 * 1000; // Use this to determine when to refresh tokens

const fetchWithHengeAuth: {
  [Method in "GET" | "DELETE" | "POST" | "PUT"]: <Res, Args>(
    args: MakeFetchInitOptionsParameters<Args>,
    authTokens: HengeAuthTokens | undefined,
    refreshTokenNeeded?: boolean,
  ) => Promise<Res>;
} = {
  GET: (...args) => fetchWithHengeAuthTokens("GET", ...args),
  DELETE: (...args) => fetchWithHengeAuthTokens("DELETE", ...args),
  POST: (...args) => fetchWithHengeAuthTokens("POST", ...args),
  PUT: (...args) => fetchWithHengeAuthTokens("PUT", ...args),
};

export default fetchWithHengeAuth;

// Define the main function for making authenticated requests
async function fetchWithHengeAuthTokens<Res, Args>(
  method: "GET" | "DELETE" | "POST" | "PUT",
  args: MakeFetchInitOptionsParameters<Args>,
  authTokens: HengeAuthTokens | undefined,
  refreshTokenNeeded: boolean = false,
) {
  if (!authTokens) {
    throw new Error("No HengeAuthTokens.");
  }
  if (!authTokens.accessToken) {
    throw new Error("No AccessToken.");
  }
  if (refreshTokenNeeded && !authTokens.refreshToken) {
    throw new Error("No RefreshToken.");
  }

  const fetchInitOptions: FetchInitOptions = makeFetchInits<Args>({
    ...args,
  });

  // Create a function to make the fetch request with the appropriate credentials
  const requestToFetch = makeFetchWithAuth<Res>(
    method,
    fetchInitOptions,
    authTokens,
    refreshTokenNeeded,
  );

  // Check if the access token is about to expire, and refresh it if needed
  if (authTokens.accessToken.exp - (Date.now() + MAX_TIME_REFRESH) < 0) {
    try {
      // Attempt to refresh the tokens
      const refreshAccessTokenRes =
        await fetchRefreshAccessTokenAPI(authTokens);
      // If successful, save the new tokens and retry the original request
      if (
        refreshAccessTokenRes.code === ApiResultEnum.OK.code &&
        "data" in refreshAccessTokenRes
      ) {
        memberStore
          .getState()
          .memberStoreActions.updateAccessToken(
            refreshAccessTokenRes.data.tokens.accessToken,
          );

        return requestToFetch(refreshAccessTokenRes.data.tokens.accessToken);
      }

      throw new Error();
    } catch (e) {
      fetchTerminateTokenAPI(authTokens);
      void e;
    }

    // If token refresh fails, terminate tokens
    fetchTerminateTokenAPI(authTokens);
  }

  // If the access token is still valid, proceed with the original request
  return requestToFetch();
}

// Function to create a fetch function with the specified credentials

type FetchCallbackWithAuth<Res> = (newAccessToken?: AuthToken) => Promise<Res>;

function makeFetchWithAuth<Res>(
  method: "GET" | "DELETE" | "POST" | "PUT",
  fetchInitOptions: FetchInitOptions,
  authTokens: HengeAuthTokens,
  refreshTokenNeeded: boolean = false,
): FetchCallbackWithAuth<Res> {
  return async function (newAccessToken) {
    const headers: Record<string, string> = {};
    headers["x-hng-access-token"] =
      `Bearer ${newAccessToken?.value ?? authTokens.accessToken.value}`;
    if (refreshTokenNeeded) {
      headers["x-hng-refresh-token"] =
        `Bearer ${authTokens.refreshToken.value}`;
    }

    // Make a fetch request to the specified path with the provided or refreshed access token
    return fetch(
      `${typeof window === "undefined" ? process.env.FETCH_SSR_BASE_URL : process.env.FETCH_CSR_BASE_URL}${fetchInitOptions.path}`,
      {
        ...fetchInitOptions.init,
        method,
        headers: { ...fetchInitOptions.init?.headers, ...headers },
      },
    ).then((res) => res.json());
  };
}

////////////////
//    Auth    //
////////////////

export const fetchGenerateTokenByNativeAPI = (
  request: GenerateTokenRequest,
): Promise<GenerateTokensResponse | NotVerifiedVerificationResponse> => {
  return fetch(
    `${typeof window === "undefined" ? process.env.FETCH_SSR_BASE_URL : process.env.FETCH_CSR_BASE_URL}/api/v1/auth/token/generate/native`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    },
  ).then((res) => res.json());
};

export const fetchRefreshAccessTokenAPI = (
  authTokens: HengeAuthTokens,
): Promise<RefreshAccessTokenResponse | UnauthorizedResponse> => {
  return fetch(
    `${typeof window === "undefined" ? process.env.FETCH_SSR_BASE_URL : process.env.FETCH_CSR_BASE_URL}/api/v1/auth/token/refresh`,
    {
      method: "POST",
      headers: {
        "x-hng-access-token": `Bearer ${authTokens.accessToken.value}`,
        "x-hng-refresh-token": `Bearer ${authTokens.refreshToken.value}`,
      },
    },
  ).then((res) => res.json());
};

export const fetchTerminateTokenAPI = (
  authTokens: HengeAuthTokens | undefined,
) => {
  if (!authTokens) {
    return signOut({ callbackUrl: "/login", redirect: true });
  }

  return fetch(
    `${typeof window === "undefined" ? process.env.FETCH_SSR_BASE_URL : process.env.FETCH_CSR_BASE_URL}/api/v1/auth/token/terminate`,
    {
      method: "POST",
      headers: {
        "x-hng-access-token": `Bearer ${authTokens.accessToken.value}`,
        "x-hng-refresh-token": `Bearer ${authTokens.refreshToken.value}`,
      },
    },
  )
    .then((res) => res.json())
    .finally(() => {
      try {
        window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Log_Out());
      } catch (e) {
        void e;
      }

      signOut({ callbackUrl: "/login", redirect: true });
    });
};
