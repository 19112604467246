import {
  MotionText,
  MotionTextProps,
} from "@/components/motion-text/MotionText";
import { css } from "@emotion/react";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";
import { AnimatePresence, MotionProps, motion } from "framer-motion";

const DEFAULT_TEXT_KIND = HENGE.TEXT_KIND.heading2;
const DEFAULT_DESCRIPTION_TEXT_KIND = HENGE.TEXT_KIND.heading3;
const DEFAULT_TEXT_ALIGN = HENGE.TEXT_ALIGN.center;

interface CardTitleProps {
  children: string;
  style?: React.CSSProperties;
  textStyleProps?: Partial<HUI.TextStyleProps>;
  motionText?: boolean;
  motionTextProps?: MotionTextProps;
  label?: string;
  description?: string;
  descriptionTextStyleProps?: Partial<HUI.TextStyleProps>;
  framerMotionProps?: MotionProps;
}

export const CardTitle = ({
  children,
  style,
  textStyleProps,
  motionText,
  motionTextProps,
  label,
  description,
  descriptionTextStyleProps,
  framerMotionProps,
}: CardTitleProps) => {
  let flexAlignItemsByTextAlign: string = HENGE.FLEX_ALIGN.start;
  if (textStyleProps?.align === HENGE.TEXT_ALIGN.center) {
    flexAlignItemsByTextAlign = HENGE.FLEX_ALIGN.center;
  } else if (textStyleProps?.align === HENGE.TEXT_ALIGN.right) {
    flexAlignItemsByTextAlign = HENGE.FLEX_ALIGN.end;
  } else if (textStyleProps?.align === HENGE.TEXT_ALIGN.justify) {
    flexAlignItemsByTextAlign = HENGE.FLEX_ALIGN["space-between"];
  }

  return (
    <AnimatePresence>
      <motion.div
        style={style}
        css={css`
          display: flex;
          flex-flow: column nowrap;
          justify-content: start;
          align-items: ${flexAlignItemsByTextAlign};
          width: 100%;
        `}
        {...framerMotionProps}
      >
        <div>
          {label && (
            <HUI.Text
              kind={HENGE.TEXT_KIND.body2}
              colour={HENGE.SEMANTIC_TEXT_COLOUR["text-caption"]}
              align={
                textStyleProps?.align === HENGE.TEXT_ALIGN.right
                  ? HENGE.TEXT_ALIGN.right
                  : HENGE.TEXT_ALIGN.left
              }
              lineHeight={HENGE.TEXT_LINE_HEIGHT.small}
            >
              {label}
            </HUI.Text>
          )}
          {motionText ? (
            <MotionText
              {...motionTextProps}
              textStyleProps={{
                kind: DEFAULT_TEXT_KIND,
                ...textStyleProps,
              }}
            >
              {children}
            </MotionText>
          ) : (
            <HUI.Text
              kind={DEFAULT_TEXT_KIND}
              align={DEFAULT_TEXT_ALIGN}
              {...textStyleProps}
            >
              {children}
            </HUI.Text>
          )}
        </div>
        <div
          css={css`
            width: 100%;
          `}
        >
          {description && (
            <HUI.Text
              kind={DEFAULT_DESCRIPTION_TEXT_KIND}
              {...descriptionTextStyleProps}
            >
              {description}
            </HUI.Text>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
