import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export const PreloadAssets = () => {
  //================================================================
  // Gaia
  //================================================================

  useLoader.preload(
    GLTFLoader,
    `${process.env.ASSET_URL ?? ""}/assets/models/utility/gaia/TrashCan.glb`,
  );

  //================================================================
  // Henge
  //================================================================

  // fallback/err

  useLoader.preload(
    GLTFLoader,
    `${process.env.ASSET_URL ?? ""}/assets/models/utility/henge/fallback/error/error.glb`,
  );

  // loader/in-process

  useLoader.preload(
    GLTFLoader,
    `${process.env.ASSET_URL ?? ""}/assets/models/utility/henge/loader/in-process/loading.glb`,
  );

  //================================================================

  return <></>;
};
